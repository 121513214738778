import SEO from "../../components/SEO";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../utils";
import axios from "axios";
import {
  useCreateGalleryMutation,
  useGetAllAdmissionDocQuery,
} from "../../Slices/apisSlice";
import PageLoading from "../../components/preLoaders/PageLoading";
import Loading from "../../components/preLoaders/Loading";
import Spinner from "../../components/preLoaders/Spinner";
import MultiImageUploader from "../../components/MultiplyImageUploader";

function Admissions() {
  const [images, setImages] = useState([]);
  const [imageId, setImageId] = useState("");
  const [link, setLink] = useState("");
  const [deleteSucess, setDeleteSucess] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const { data, isLoading, refetch, isError } = useGetAllAdmissionDocQuery();
  const [edit, setEdit] = useState(false);
  const [create, setCreate] = useState(false);
  const [update, setUpdate] = useState(false);
  const [load, setLoad] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);

  const { register, handleSubmit, setValue, reset } = useForm();
  const [allUploadedUrls, setAllUploadedUrls] = useState([]); // State to store all uploaded URLs

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (allUploadedUrls) {
      setValue("fileUrl", allUploadedUrls);
    }

    if (data) {
      setImages(data.data.results);
      setValue("category", "admission_document");
      setValue("description", "Admission_document");
    }
  }, [data, allUploadedUrls]);

  const handleUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "pmnz4ina"); // Replace with your Cloudinary upload preset
    setLoadingUpload(true);
    try {
      const response = await fetch(
        "https://api.cloudinary.com/v1_1/dlptkcsc4/image/upload",
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();
      setValue("fileUrl", data.secure_url);
      setLoadingUpload(false);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const updateUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "pmnz4ina"); // Replace with your Cloudinary upload preset
    setLoadingUpload(true);
    try {
      const response = await fetch(
        "https://api.cloudinary.com/v1_1/dlptkcsc4/image/upload",
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();
      setValue("image", data.secure_url);
      setLoadingUpload(false);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const [createImage, { isLoading: loading }] = useCreateGalleryMutation();
  const createHandler = async (data) => {
    try {
      await createImage(data);
      toast.success("Successfully Added Image");
      setCreate(false);
      refetch();
    } catch (err) {
      toast.error(err?.data?.message || err.error, {
        position: "top-left",
      });
    }
  };

  const { userInfo } = useSelector((state) => state.auth);
  const token = userInfo.data.accessToken;

  const editHandler = async (data) => {
    try {
      setLoad(true);
      const res = await axios.post(`${BASE_URL}admin/image/${imageId}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
          accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      const message = res.data.message;
      setLoad(false);
      toast.success(message);
      setEdit(false);
      refetch();
    } catch (err) {
      toast.error(err?.message || err?.data.message);
    }
  };

  const getHandler = async (id) => {
    try {
      const res = await axios.get(`${BASE_URL}content/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
          accept: "application/json",
        },
      });

      setValue("title", res.data.data.title);

      setEdit(true);
    } catch (err) {
      toast.error(err?.data?.message || err?.message);
    }
  };



  const deleteHandler = async (data) => {
    try {
      const res = await axios.patch(
        `${BASE_URL}content/${imageId}`,
        { image: link },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
            accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      const message = res.data.message;
      toast.success(message);
      setDeleteModal(false);
      setDeleteSucess(true);
      refetch();
    } catch (err) {
      toast.error(err?.message || err?.data.message);
    }
  };

  return (
    <>
      <SEO
        title="Admission"
        description="The Best Place To Enroll Your Child/Ward."
        name="CHARISMABASE"
        type="description"
      />
      <div>
        <div className="flex justify-between items-center px-5 mt-5  ">
          <div className="flex items-center gap-x-10">
            <h2 className="text-gray-900   text-lg font-extrabold text-left rounded-2xl focus:outline-none block  ">
              Admission
            </h2>
          </div>

          <button
            onClick={() => {
              setCreate(true);
            }}
            className="flex transition-colors duration-200   hover:text-[#4CC5D2]/50 focus:outline-none text-[#fff]  bg-[#a020f0] px-3 py-1 rounded-md gap-x-1 items-center "
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 3.33331V12.6666"
                stroke="CurrentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.33325 8H12.6666"
                stroke="CurrentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span>Add Document</span>
          </button>
        </div>
        <div className="p-2 lg:p-6  w-full ">
          <div className="p-2 bg-[#fff] rounded-2xl">
            {isLoading ? (
              <div className="pb-5">
                <PageLoading />
              </div>
            ) : isError ? (
              <div className="pt-5">
                <Loading />
              </div>
            ) : (
              <section className="overflow-x-auto rounded-lg h-[32rem] scrollbar-hide">
                <div className="container  grid sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-2 bg-[#FFFFFF]  rounded mx-auto  p-5">
                  {images.map((item, index) => (
                    <div key={index} className=" rounded-xl shadow-lg  border">
                      <div className="w-full group container  rounded-md max-w-sm flex justify-center items-center  mx-auto ">
                        <div className="flex justify-center">
                          <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="text-[#a020f0] w-[8rem] h-[8rem] lg:w-[20rem] lg:h-[20rem]"
                          >
                            <path
                              d="M17.5 2.5H7.5C6.83696 2.5 6.20107 2.76339 5.73223 3.23223C5.26339 3.70107 5 4.33696 5 5V25C5 25.663 5.26339 26.2989 5.73223 26.7678C6.20107 27.2366 6.83696 27.5 7.5 27.5H22.5C23.163 27.5 23.7989 27.2366 24.2678 26.7678C24.7366 26.2989 25 25.663 25 25V10L17.5 2.5Z"
                              stroke="CurrentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M17.5 2.5V10H25"
                              stroke="CurrentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M20 16.25H10"
                              stroke="CurrentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M20 21.25H10"
                              stroke="CurrentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M12.5 11.25H11.25H10"
                              stroke="CurrentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>

                        <div className="absolute opacity-0 fd-sh group-hover:opacity-100">
                          <div className="flex gap-x-3 text-center">
                            <button
                              onClick={() => {
                                setImageId(item._id);
                                setDeleteModal(true);
                              }}
                              className="text-center rounded-lg  bg-white text-gray-700 font-bold text-xs"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                className="w-3  sm:w-5 sm:h-5"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                />
                              </svg>
                            </button>
                            <button
                              onClick={() => {
                                getHandler(item._id);
                              }}
                              className="text-center rounded-lg  bg-white text-gray-700 font-bold text-xs p-1 cursor-pointer"
                            >
                              <svg
                                width="22"
                                height="22"
                                viewBox="0 0 22 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-4 h-4  mt-0.5"
                              >
                                <path
                                  d="M16.1714 1.91436C16.434 1.65172 16.7458 1.44338 17.089 1.30124C17.4322 1.1591 17.8 1.08594 18.1714 1.08594C18.5428 1.08594 18.9106 1.1591 19.2538 1.30124C19.5969 1.44338 19.9087 1.65172 20.1714 1.91436C20.434 2.17701 20.6424 2.48881 20.7845 2.83197C20.9267 3.17513 20.9998 3.54293 20.9998 3.91436C20.9998 4.2858 20.9267 4.6536 20.7845 4.99676C20.6424 5.33992 20.434 5.65172 20.1714 5.91436L6.67139 19.4144L1.17139 20.9144L2.67139 15.4144L16.1714 1.91436Z"
                                  stroke="CurrentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="p-3">
                        <p className="text-sm flex items-center justify-between font-bold text-gray-900 uppercase">
                          {item.title}
                        </p>
                        <p className="text-sm  flex items-center justify-between font-semibold text-gray-500 ">
                          {item.description}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </section>
            )}
          </div>
        </div>
        {create && (
          <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
            <div className="flex items-end justify-center sm:min-h-screen md:px-4 pt-4 pb-10 text-center sm:block sm:p-0">
              <span
                className="hidden sm:inline-block sm:h-screen sm:align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>

              <div className="relative inline-block px-4 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  top-20 md:top-0  sm:my-5 w-full sm:max-w-md sm:p-6  sm:align-middle">
                <div className="flex items-start justify-between ">
                  <div className=" flex justify-between font-semibold py-2">
                    <p className="text-xs  text-gray-900 ">Add Document</p>
                  </div>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setCreate(false);
                    }}
                    type="button"
                    className="text-gray-900 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    data-modal-toggle="default-modal"
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>

                <form onSubmit={handleSubmit(createHandler)} className="mt-4">
                  <div className="w-full my-2">
                    <label className="relative bg-white text-center py-2 w-44  rounded-full px-auto  z-10 block text-xs text-[#495454] font-normal">
                      Upload Document
                    </label>
                    <input
                      type="text"
                      {...register("fileUrl")}
                      className="hidden"
                    />
                    <MultiImageUploader
                      setAllUploadedUrls={setAllUploadedUrls}
                    />
                  </div>

                  <div className="mb-5 ">
                    <label className="relative bg-white w-12 ml-5 text-[#495454] rounded-full px-auto -mb-2 z-10 block text-xs text font-normal">
                      Title
                    </label>
                    <input
                      type="text"
                      required
                      {...register("title")}
                      className="py-3 px-5 border-[0.5px] bg-[#F6F8F8] border-[#a020f0] rounded-full w-full focus:outline-none"
                    />
                  </div>

                  <input
                    type="text"
                    {...register("category")}
                    className="hidden"
                  />
                  <input
                    type="text"
                    {...register("description")}
                    className="hidden "
                  />

                  <div className="flex gap-x-2 mt-4 w-full">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setCreate(false);
                      }}
                      className="w-full flex item-center text-sm justify-center rounded-3xl p-3 text-[#a020f0] border border-[#a020f0] font-bold"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      value="submit"
                      disabled={loading || loadingUpload}
                      className="w-full bg-[#a020f0] text-sm rounded-3xl p-3 text-white font-semibold transition duration-200 hover:bg-[#757E7E]"
                    >
                      {loading ? <Spinner /> : "Submit"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}

       

        {edit && (
          <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
            <div className="flex items-end justify-center sm:min-h-screen md:px-4 pt-4 pb-10 text-center sm:block sm:p-0">
              <span
                className="hidden sm:inline-block sm:h-screen sm:align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>

              <div className="relative inline-block px-4 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  top-20 md:top-0  sm:my-5 w-full sm:max-w-md sm:p-6  sm:align-middle">
                <div className="flex items-start justify-between ">
                  <div className=" flex justify-between font-semibold py-2">
                    <p className="text-xs  text-gray-900 ">Edit Document</p>
                  </div>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setEdit(false);
                    }}
                    type="button"
                    className="text-gray-900 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    data-modal-toggle="default-modal"
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>

                <form onSubmit={handleSubmit(editHandler)} className="mt-4">
                  <div className="mb-5">
                    <label className="relative bg-white w-12 ml-5 text-[#495454] rounded-full px-auto -mb-2 z-10 block text-xs text font-normal">
                      Title
                    </label>
                    <input
                      type="text"
                      required
                      {...register("title")}
                      className="py-3 px-5 border-[0.5px] bg-[#F6F8F8] border-[#a020f0] rounded-full w-full focus:outline-none"
                    />
                  </div>

                  <div className="w-full my-2">
                    <label className="relative bg-white text-center py-2 w-44  rounded-full px-auto  z-10 block text-xs text-[#495454] font-normal">
                      Image Thumbnail (Picture)
                    </label>
                    <input
                      type="text"
                      {...register("fileUrl")}
                      className="hidden "
                    />
                    <MultiImageUploader
                      setAllUploadedUrls={setAllUploadedUrls}
                    />
                  </div>

                  <div className="flex gap-x-2  w-full">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setEdit(false);
                        reset();
                      }}
                      className="w-full flex item-center text-sm justify-center rounded-3xl p-3 text-[#a020f0] border border-[#a020f0] font-bold"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      value="submit"
                      disabled={load || loadingUpload}
                      className="w-full bg-[#a020f0] text-sm rounded-3xl p-3 text-white font-semibold transition duration-200 hover:bg-[#757E7E]"
                    >
                      {load ? <Spinner /> : "Save"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}

        {deleteModal && (
          <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
            <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
              <span
                className="hidden sm:inline-block sm:h-screen sm:align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>

              <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-md sm:p-6 md:p-8 sm:align-middle ">
                <div className="flex items-start justify-between ">
                  <h3 className="text-[#000] text-xl font-semibold dark:text-white">
                    Confirm Document Delete
                  </h3>
                </div>

                <p className="text-left py-5 text-sm text-[#141414]/70">
                  Are you sure you want to delete this Document?
                </p>
                <div className="flex gap-x-3 items-start justify-between">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setDeleteModal(false);
                    }}
                    className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#0F3776] bg-[#D6DDE8]  px-3 py-4 rounded-lg "
                  >
                    No, Cancel
                  </button>
                  <button
                    onClick={() => {
                      deleteHandler();
                    }}
                    className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#CA0D0D]  px-3 py-4 rounded-lg "
                  >
                    Yes, Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {deleteSucess && (
          <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
            <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
              <span
                className="hidden sm:inline-block sm:h-screen sm:align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>

              <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-md sm:p-6 md:p-8 sm:align-middle ">
                <div className="flex items-start justify-between ">
                  <h3 className="text-[#000] text-xl font-semibold dark:text-white">
                    Document Deleted Successfully
                  </h3>
                </div>

                <p className="text-left py-5 text-sm text-[#D3B61F]">
                  You have successfully Deleted this Document
                </p>
                <div className="flex gap-x-3 items-start justify-between">
                  <button
                    onClick={(e) => {
                      setDeleteSucess(false);
                      refetch();
                    }}
                    className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#0F3776]  px-3 py-4 rounded-lg "
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Admissions;
