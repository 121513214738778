import React, { useEffect, useState } from "react";
import SEO from "../components/SEO";
import { motion, AnimatePresence } from "framer-motion";
import TestimonySlide from "../components/TestimonySlide";
import PageLoading from "../components/preLoaders/PageLoading";
import Loading from "../components/preLoaders/Loading";
import { useGetAllGalleryImagesQuery } from "../Slices/apisSlice";

function Gallery() {
  const [images, setImages] = useState([]);
  const { data, isLoading, refetch, isError } = useGetAllGalleryImagesQuery();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (data) {
      setImages(data.data.results);
    }
  }, [data]);

  return (
    <div>
      
      <SEO
        title="SCHOOL GALLERY"
        description="The Best Place To Enroll Your Child/Ward."
        name="CHARISMABASE"
        type="description"
      />
      <div className=" text-gray-600">
        <div className="relative flex items-center h-[15rem] lg:h-[18rem] overflow-hidden">
          <img
            src="GCSSimg/Learners 10.jpg"
            alt="i"
            className="w-full bg-bottom bg-cover"
          />

          <div className="absolute  bg-[#181d38]/70  cursor-pointer h-[15rem] lg:h-[25rem] w-full text-center">
            <div className="flex items-center justify-center w-full h-full  ">
              <motion.div
                initial={{ x: 300, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: -300, opacity: 0 }}
                className="text-center "
              >
                <h1 className="text-2xl font-bold text-white  lg:text-7xl">
                  SCHOOL GALLERY
                </h1>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
      {isLoading ? (
        <div className="py-5">
          <PageLoading />
        </div>
      ) : isError ? (
        <div className="py-5">
          <Loading />
        </div>
      ) : (
        <section className="container bg-[#FFFFFF]  rounded mx-auto space-y-5 p-5">
          {images.map((item, index) => (
            <div key={index} className="sm:p-5 sm:bg-slate-100  rounded-lg">
              <p className="text-base lg:text-2xl my-3 flex items-center justify-between font-bold text-gray-900 ">
                {item.title}
              </p>

              <div className="container  mx-auto grid grid-cols-1 lg:grid-cols-4 rounded-md gap-2   ">
                {item.fileUrl.map((items, index) => (
                  <div key={index} className="overflow-hidden   rounded-md">
                    <img
                      src={items}
                      alt="i"
                      className="h-[18rem] object-cover w-full hover:shadow-xl hover:transform hover:scale-105 duration-300"
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </section>
      )}

      <div className=" bg-[#fff] py-10 lg:py-20 px-2  ">
        {/* Testimonies *********************************************/}
        <div className="flex items-center max-w-min gap-x-2 mx-auto">
          <div className="flex flex-col justify-end items-end space-y-1">
            <div className="w-10 h-0.5 rounded bg-[#a020f0] "></div>
            <div className="w-16 h-0.5 rounded bg-[#a020f0]"></div>
          </div>
          <h2 className="text-base text-[#a020f0] font-semibold  text-center md:text-xl uppercase mx-auto">
            Testimonial
          </h2>
          <div className="flex flex-col space-y-1">
            <div className="w-10 h-0.5 rounded bg-[#a020f0]"></div>
            <div className="w-16 h-0.5 rounded bg-[#a020f0]"></div>
          </div>
        </div>
        <h2 className="text-lg text-[#000] font-bold  text-center md:text-4xl my-5 lg:w-[35rem] mx-auto">
          Our Parents Say!
        </h2>
        <h2 className="mx-auto text-[10px] text-[#36474F] text-center font-semibold md:text-sm md:w-[44rem] lg:w-[44rem] flex items-center justify-center gap-x-2">
          <img
            className="object-cover object-center w-auto h-5 md:w-auto md:h-9 "
            src="../Frame 41.png"
            alt=""
          />{" "}
          over 100+ Parents trust us
        </h2>
        <section className="mx-auto  justify-center md:space-x-2 my-5 lg:my-10 ">
          <div className="w-full ">
            <TestimonySlide />
          </div>
        </section>
      </div>
    </div>
  );
}
export default Gallery;
