import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import SEO from "../components/SEO";
import { useGetAllBlogQuery } from "../Slices/apisSlice";
import PageLoading from "../components/preLoaders/PageLoading";
import Loading from "../components/preLoaders/Loading";
import FormattedDate from "../components/FormattedDate";

function Announcement() {
  const [blog, setBlog] = useState([]);
  const { data, isLoading, refetch, isError } = useGetAllBlogQuery();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (data) {
      setBlog(data.data.results);
    }
  }, [data]);
  return (
    <>
      <SEO
        title="Announcement"
        description="The Best Place To Enroll Your Child/Ward."
        name="CHARISMABASE"
        type="description"
      />
      <div className=" bg-[#FFF] py-10 lg:py-20 px-2 lg:px-24  flex flex-col  items-center  justify-center gap-x-1 ">
        <div className="flex items-center max-w-min gap-x-2 mx-auto">
          <div className="flex flex-col justify-end items-end space-y-1">
            <div className="w-10 h-0.5 rounded bg-[#a020f0] "></div>
            <div className="w-16 h-0.5 rounded bg-[#a020f0]"></div>
          </div>
          <h2 className="text-base text-[#a020f0] font-semibold  text-center md:text-xl uppercase mx-auto">
            Announcements
          </h2>
          <div className="flex flex-col space-y-1">
            <div className="w-10 h-0.5 rounded bg-[#a020f0]"></div>
            <div className="w-16 h-0.5 rounded bg-[#a020f0]"></div>
          </div>
        </div>

        {isLoading ? (
          <div className="py-5">
            <PageLoading />
          </div>
        ) : isError ? (
          <div className="py-5">
            <Loading />
          </div>
        ) : (
          <motion.div
            initial={{ x: 20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="grid gap-5 grid-cols-1 md:grid-cols-2 mt-10"
          >
            {blog.map((item, index) => (
              <motion.div
                initial={{ y: -70, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.9 }}
                className="shadow "
                key={index}
              >
                <img
                  className="object-cover object-center w-full h-64 rounded-t-lg lg:h-80"
                  src={item.fileUrl[0]}
                  alt=""
                />
               

                <div className="p-4 bg-[#F0FBFC]">
                  <div className="flex items-center gap-x-2">
                    <h1 className="text-xs sm:text-sm font-semibold text-[#a020f0] uppercase">
                      {item.title}
                    </h1>

                    <p className="text-xs sm:text-sm  text-[#808080] font-semibold flex items-center gap-x-1">
                      <span className="bg-[#7E7E7E] rounded-full h-1 w-1"></span>

                      <FormattedDate date={item.createdAt} />
                    </p>
                  </div>
                  <h1 className="text-gray-900 text-base md:text-lg">
                    {item.description}
                  </h1>
                </div>
              </motion.div>
            ))}
          </motion.div>
        )}
      </div>
    </>
  );
}

export default Announcement;
