import React, { useEffect, useState } from "react";




function Index() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <div className="p-3 lg:p-10 max-w-7xl">
        <div className=" bg-white  rounded-lg p-4  lg:p-12">
          <h2 className="text-[#022045]   text-4xl font-extrabold text-left   ">
            Welcome Back, Admin!!
          </h2>
          <div class="font-light text-base pt-5 ">
            Great Charismabase Standard Schools provides opportunities for all
            students to engage in all round education program.
          </div>
          <div class="font-light text-base pt-5 ">
            We have staff members that are dedicated to promoting high
            achievements for all students through: Infrastructure and technology
            that supports 21st Century Learning.
          </div>
          <div className="container m-auto  text-gray-600 my-10">
            <div className="space-y-6 md:space-y-0 flex flex-col xl:flex-row md:gap-6 lg:items-center lg:gap-8 px-2 ">
              <div className="flex items-center gap-x-4 px-5  bg-[#F9F9F9] xl:w-6/12 rounded-xl">
                <img src="../mission.png" className="h-28" alt="i" />
                <div>
                  <h1 className="text-lg mt-5 sm:text-xl font-semibold text-gray-800">
                    Our Mission
                  </h1>
                  <p className="my-3  text-sm font-light text-[#62747d]">
                    Great Charismabase Standard Schools provides opportunities
                    for all students to engage in all round education program.
                    We have staff members that are dedicated to promoting high
                    achievements for all students through: Infrastructure and
                    technology that supports 21st Century Learning.
                  </p>
                </div>
              </div>

              <div className="flex items-center  gap-x-4 px-5  bg-[#F9F9F9] xl:w-6/12 rounded-xl">
                <img src="../vision.png" className="h-28" alt="i" />
                <div>
                  <h1 className="text-lg mt-5 sm:text-xl font-semibold text-gray-800">
                    Our Vision
                  </h1>
                  <p className="my-3 text-sm font-light text-[#62747d]">
                    Our vision is to empower students to acquire, demonstrate,
                    and articulate value, knowledge and skills; to have
                    competitive and highly productive citizens in our society.
                  </p>
                  <p className="mt-1 text-sm font-light text-[#62747d] hidden xl:block">
                    <br />
                    <br />
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Index;
