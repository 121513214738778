import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../utils";
import { logout, setTokens } from "./authSlice";

export const apiOne = createApi({
  reducerPath: "apiOne",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    // ...endpoints
  }),
});

// export const apiTwo = createApi({
// 	reducerPath: 'apiTwo',
// 	baseQuery: fetchBaseQuery({
// 		baseUrl: BASE_URL,
// 		prepareHeaders: (headers, { getState }) => {
// 			const token = getState().auth.userInfo.data.accessToken;
// 			if (token) {
// 				headers.set('authorization', `Bearer ${token}`);
// 			}

// 			return headers;
// 		},
// 	}),
// 	endpoints: (builder) => ({
// 		// ...endpoints
// 	}),
// });

export const apiTwo = createApi({
  reducerPath: "apiTwo",
  baseQuery: async (args, api, extraOptions) => {
    const baseQuery = fetchBaseQuery({
      baseUrl: BASE_URL,
      prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.userInfo?.data?.accessToken;

        // Check if access token exists and is valid
        if (token) {
          headers.set("authorization", `Bearer ${token}`);
        } else {
          console.error("Access token is missing");
        }

        return headers;
      },
    });

    // Make the initial request
    let result = await baseQuery(args, api, extraOptions);

    // If the request returns 401 (unauthorized), attempt token refresh
    if (result?.error?.status === 401) {
      const refreshToken = api.getState().auth.userInfo?.data?.refreshToken;

      if (refreshToken) {
        // Send a request to refresh the token
        const refreshResult = await baseQuery(
          {
            url: "/refresh-token",
            method: "POST",
            body: { refreshToken },
          },
          api,
          extraOptions
        );

        if (refreshResult?.data) {
          const newAccessToken = refreshResult.data.accessToken;
          const newRefreshToken = refreshResult.data.refreshToken;

          // Update the store with new tokens
          api.dispatch(
            setTokens({
              accessToken: newAccessToken,
              refreshToken: newRefreshToken,
            })
          );

          // Retry the original request with the new access token
          result = await baseQuery(
            {
              ...args,
              headers: {
                ...args.headers,
                authorization: `Bearer ${newAccessToken}`, // Add the new access token to the header
              },
            },
            api,
            extraOptions
          );
        } else {
          // If refresh token fails, log the user out
          console.error("Failed to refresh token, logging out...");
          api.dispatch(logout());
        }
      } else {
        console.error("Refresh token is missing");
      }
    }

    // Check if result still has a 403 error (this happens if no token was provided)
    if (result?.error?.status === 403) {
      console.error("403 Forbidden: Access token is required");
    }

    return result;
  },
  endpoints: (builder) => ({
    // Define your API endpoints here
  }),
});
