import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import FormattedDate from "../../components/FormattedDate";

import {
  useCreateBlogMutation,
  useDeleteBlogMutation,
  useGetAllBlogQuery,
} from "../../Slices/apisSlice";
import { BASE_URL } from "../../utils";
import PageLoading from "../../components/preLoaders/PageLoading";
import Spinner from "../../components/preLoaders/Spinner";
import MultiImageUploader from "../../components/MultiplyImageUploader";

function Blog() {
  const [modal, setModal] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const [blogId, setBlogId] = useState("");
  const [deleteSucess, setDeleteSucess] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [details, setDetails] = useState([]);
  const { data, isLoading, refetch, error } = useGetAllBlogQuery();
  const [edit, setEdit] = useState(false);
  const [create, setCreate] = useState(false);
  const [load, setLoad] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const { register, handleSubmit, setValue, reset } = useForm();
  const [allUploadedUrls, setAllUploadedUrls] = useState([]); // State to store all uploaded URLs

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (allUploadedUrls) {
      setValue("fileUrl", allUploadedUrls);
    }

    if (data) {
      setBlogs(data.data.results);
      setValue("category", "content");
    }
  }, [data, allUploadedUrls]);

  const [searchQuery, setSearchQuery] = useState("");
  const filteredBlogs = blogs.filter((item) =>
    item.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const { userInfo } = useSelector((state) => state.auth);
  const token = userInfo.data.accessToken;

  const getHandler = async (id) => {
    try {
      const res = await axios.get(`${BASE_URL}content/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
          accept: "application/json",
        },
      });
      const response = res.data;
      setDetails(response);
      setModal(true);
    } catch (err) {
      toast.error(err?.data?.message || err?.message);
    }
  };

  const getEditHandler = async (id) => {
    try {
      const res = await axios.get(`${BASE_URL}content/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
          accept: "application/json",
        },
      });

      const response = res.data;
      // Set form values with fetched data
      setValue("fileUrl", response.fileUrl);
      setValue("title", response.title);
      setValue("description", response.description);

      setEdit(true);
    } catch (err) {
      toast.error(err?.data?.message || err?.message);
    }
  };

  const updateHandler = async (data) => {
    try {
      setLoad(true);
      const res = await axios.put(`${BASE_URL}content/${blogId}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
          accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      const message = res.data.message;
      setLoad(false);
      toast.success(message);
      setEdit(false);
      refetch();
    } catch (err) {
      toast.error(err?.message || err?.data.message);
    }
  };

  const [createBlog, { isLoading: loading }] = useCreateBlogMutation();
  const createHandler = async (data) => {
    try {
      await createBlog(data);
      toast.success("Successfully Added Blog");
      setCreate(false);
      refetch();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const [deleteevent] = useDeleteBlogMutation();
  const deleteHandler = async (deletedata) => {
    try {
      await deleteevent(deletedata);
      setDeleteModal(false);
      setDeleteSucess(true);
      refetch();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  function truncate(str, n) {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  }

  const handleUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "pmnz4ina"); // Replace with your Cloudinary upload preset
    setLoadingUpload(true);
    try {
      const response = await fetch(
        "https://api.cloudinary.com/v1_1/dlptkcsc4/image/upload",
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();
      setValue("fileUrl", data.secure_url);
      setLoadingUpload(false);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };
  return (
    <div>
      <div className="flex justify-between items-center px-5 mt-5  ">
        <div className="flex items-center gap-x-10">
          <h2 className="text-gray-900   text-lg font-extrabold text-left rounded-2xl focus:outline-none block  ">
            Announcements
          </h2>
        </div>

        <button
          onClick={() => {
            setCreate(true);
          }}
          className="flex transition-colors duration-200   hover:text-[#4CC5D2]/50 focus:outline-none text-[#fff]  bg-[#a020f0] px-3 py-1 rounded-md gap-x-1 items-center "
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 3.33331V12.6666"
              stroke="CurrentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M3.33325 8H12.6666"
              stroke="CurrentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span>Add New Announcement</span>
        </button>
      </div>
      <div className="p-2 lg:p-6  w-full ">
        <div className="p-2 bg-[#fff] rounded-2xl">
          <div className="flex justify-between items-center mt-3 px-5">
            <div className="relative w-[30rem]">
              <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2 9C2 5.13401 5.13401 2 9 2C12.866 2 16 5.13401 16 9C16 10.8866 15.2537 12.5988 14.0402 13.8576C14.0064 13.8837 13.9738 13.9123 13.9428 13.9433C13.9118 13.9743 13.8833 14.0068 13.8572 14.0406C12.5984 15.2539 10.8863 16 9 16C5.13401 16 2 12.866 2 9ZM14.6174 16.0321C13.0778 17.2636 11.1249 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9C18 11.1251 17.2634 13.0782 16.0317 14.618L19.707 18.2933C20.0975 18.6838 20.0975 19.317 19.707 19.7075C19.3165 20.098 18.6833 20.098 18.2928 19.7075L14.6174 16.0321Z"
                    fill="#7B7C7C"
                  />
                </svg>
              </div>
              <input
                type="text"
                id="simple-search"
                className="bg-white text-gray-900 text-sm rounded-2xl focus:outline-none block w-full pl-10 p-3 placeholder-bold"
                placeholder="Search"
                required
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>

            <div className="flex justify-between   py-1 items-center gap-x-3 ">
              <button
                className="flex items-center gap-x-1 text-sm font-normal leading-6 text-gray-900  rounded-lg py-0.5 border px-2"
                aria-expanded="false"
              >
                Filters
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.59993 1.3999H12.3999C13.1333 1.3999 13.7333 1.9999 13.7333 2.73324V4.1999C13.7333 4.73324 13.3999 5.3999 13.0666 5.73324L10.1999 8.26657C9.79993 8.5999 9.53327 9.26657 9.53327 9.7999V12.6666C9.53327 13.0666 9.2666 13.5999 8.93327 13.7999L7.99994 14.3999C7.13327 14.9332 5.93327 14.3332 5.93327 13.2666V9.73324C5.93327 9.26657 5.6666 8.66657 5.39994 8.33324L2.8666 5.66657C2.53327 5.33324 2.2666 4.73324 2.2666 4.33324V2.7999C2.2666 1.9999 2.8666 1.3999 3.59993 1.3999Z"
                    stroke="#7B7B7B"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.28667 1.3999L4 6.66657"
                    stroke="#7B7B7B"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
              <button
                className="flex items-center gap-x-1 text-sm font-normal leading-6 text-gray-900  rounded-lg py-0.5 border px-2"
                aria-expanded="false"
              >
                Sort
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 4.6665H14"
                    stroke="#7B7B7B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                  <path
                    d="M4 8H12"
                    stroke="#7B7B7B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                  <path
                    d="M6.66699 11.3335H9.33366"
                    stroke="#7B7B7B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
              </button>
            </div>
          </div>

          <section class="container bg-[#FFFFFF]  rounded mx-auto  p-5">
            {isLoading ? (
              <div className="pb-5">
                <PageLoading />
              </div>
            ) : error ? (
              <div className="pt-5">
                <PageLoading />
              </div>
            ) : (
              <div class="grid grid-cols-12 gap-2 overflow-x-auto rounded-lg scrollbar-hide">
                {filteredBlogs.reverse().map((item, index) => (
                  <div key={index} className="col-span-6 bg-white flex h-36">
                    <img
                      className="w-[10rem] h-auto flex items-center justify-center"
                      src={item.fileUrl}
                      alt="pics"
                    />
                    <div className="  border text-center w-full p-3 border-[#E1EFFE] rounded-r-xl ">
                      <div className="flex items-center font-semibold text-xs text-[#022045] text-left justify-between">
                        {item.title}
                        <div className="flex gap-x-3 text-center">
                          <button
                            onClick={() => {
                              setBlogId(item._id);
                              setValue("title", item.title);
                              setValue("description", item.description);
                              setEdit(true);
                            }}
                            className="text-center rounded-lg  bg-white text-gray-700 font-bold text-xs p-1 cursor-pointer"
                          >
                            <svg
                              width="22"
                              height="22"
                              viewBox="0 0 22 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-4 h-4  mt-0.5"
                            >
                              <path
                                d="M16.1714 1.91436C16.434 1.65172 16.7458 1.44338 17.089 1.30124C17.4322 1.1591 17.8 1.08594 18.1714 1.08594C18.5428 1.08594 18.9106 1.1591 19.2538 1.30124C19.5969 1.44338 19.9087 1.65172 20.1714 1.91436C20.434 2.17701 20.6424 2.48881 20.7845 2.83197C20.9267 3.17513 20.9998 3.54293 20.9998 3.91436C20.9998 4.2858 20.9267 4.6536 20.7845 4.99676C20.6424 5.33992 20.434 5.65172 20.1714 5.91436L6.67139 19.4144L1.17139 20.9144L2.67139 15.4144L16.1714 1.91436Z"
                                stroke="CurrentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </button>
                          <button
                            onClick={() => {
                              setDeleteModal(true);
                              setBlogId(item._id);
                            }}
                            className="text-center rounded-lg  bg-white text-red-700 font-bold text-xs"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-3  sm:w-5 sm:h-5"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                      <p className="text-xs text-left font-normal pt-2">
                        {item.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </section>
        </div>
      </div>

      {modal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center sm:min-h-screen md:px-4 pt-4 pb-10 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  top-20 md:top-0  sm:my-5 w-full sm:max-w-xl sm:p-6  sm:align-middle">
              <div className="flex items-start justify-between ">
                <div className=" flex justify-between font-semibold py-2">
                  <p className="text-xs  text-gray-900 ">Announcement</p>
                </div>
              </div>
              <h3 className="text-[#000] text-left text-sm lg:text-base font-bold ">
                Image
              </h3>
              <div className=" border rounded-lg bg-[#F6F8F8] ">
                <img
                  src={details.fileUrl}
                  alt="program"
                  className="w-full h-16 rounded-lg sm:h-40 object-contain"
                />
              </div>
              <div className="">
                <div className=" my-3 h-full rounded-lg  bg-white   ">
                  <div className="flex justify-between px-3 bg-[#4CC5D2]/10 items-center py-1 my-0.5">
                    <p className="text-[10px] text-gray-700 font-bold">Title</p>
                    <div className="text-right">
                      <p className="text-xs font-bold">{details.title}</p>
                    </div>
                  </div>

                  <div className="flex justify-between px-3 bg-[#4CC5D2]/10 items-center py-1 my-0.5">
                    <p className="text-[10px] text-gray-700 font-bold">
                      Date Added
                    </p>
                    <div className="text-right">
                      <p className="text-xs font-bold">
                        <FormattedDate date={details?.createdAt} />
                      </p>
                    </div>
                  </div>
                  <div className="flex gap-x-5 justify-between px-3 bg-[#4CC5D2]/10  py-2 my-0.5">
                    <p className="text-[10px] text-gray-700 font-bold">
                      Content
                    </p>
                    <div className="text-left">
                      <p className="text-xs font-normal">
                        {details.description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex items-end justify-end gap-x-4 ">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setModal(false);
                  }}
                  className="flex transition-colors duration-200   hover:text-[#4CC5D2]/50 focus:outline-none text-[#a020f0] border border-[#a020f0] px-12 py-2 rounded-full gap-x-1 items-center "
                >
                  <span>Cancel</span>
                </button>
                <button
                  onClick={() => {
                    setBlogId(details._id);
                    getEditHandler(details._id);
                    setModal(false);
                  }}
                  className="flex transition-colors duration-200   hover:text-[#4CC5D2]/50 focus:outline-none text-[#fff]  bg-[#a020f0] px-16 py-2 rounded-full gap-x-1 items-center "
                >
                  <span>Edit</span>
                </button>
                <button
                  onClick={() => {
                    setBlogId(details._id);
                    setDeleteModal(true);
                    setModal(false);
                  }}
                  className="flex transition-colors duration-200   hover:text-[#4CC5D2]/50 focus:outline-none text-[#fff]  bg-[#cf555a] px-16 py-2 rounded-full gap-x-1 items-center "
                >
                  <span>Delete</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {create && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center sm:min-h-screen md:px-4 pt-4 pb-10 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  top-20 md:top-0  sm:my-5 w-full sm:max-w-md sm:p-6  sm:align-middle">
              <div className="flex items-start justify-between ">
                <div className=" flex justify-between font-semibold py-2">
                  <p className="text-xs  text-gray-900 ">Add Announcement</p>
                </div>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setCreate(false);
                  }}
                  type="button"
                  className="text-gray-900 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="default-modal"
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>

              <form onSubmit={handleSubmit(createHandler)} className="mt-4">
                <div className="w-full my-2">
                  <label className="relative bg-white text-center py-2 w-44  rounded-full px-auto  z-10 block text-xs text-[#495454] font-normal">
                    Image Thumbnail (Picture)
                  </label>
                  <input
                    type="text"
                    {...register("fileUrl")}
                    className="hidden"
                  />
                  <MultiImageUploader setAllUploadedUrls={setAllUploadedUrls} />
                </div>
                <div className="mb-5">
                  <label className="relative bg-white w-10 ml-5 text-[#495454] rounded-full px-auto -mb-2 z-10 block text-xs text font-normal">
                    Title
                  </label>
                  <input
                    type="text"
                    required
                    {...register("title")}
                    className="py-3 px-5 border-[0.5px] bg-[#F6F8F8] border-[#a020f0] rounded-full w-full focus:outline-none"
                  />
                </div>

                <div className="mb-2">
                  <label className="relative bg-white w-16 ml-5 text-[#495454] rounded-full px-auto -mb-2 z-10 block text-xs text font-normal">
                    Content
                  </label>
                  <textarea
                    type="text"
                    required
                    {...register("description")}
                    rows={3}
                    className="py-3 text-sm px-5 border-[0.5px] bg-[#F6F8F8] border-[#a020f0] rounded-lg w-full focus:outline-none"
                  />
                </div>
                <input
                  type="text"
                  {...register("category")}
                  className="hidden "
                />

                <div className="flex gap-x-2  w-full">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setCreate(false);
                    }}
                    className="w-full flex item-center text-sm justify-center rounded-3xl p-3 text-[#a020f0] border border-[#a020f0] font-bold"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    value="submit"
                    disabled={loading || loadingUpload}
                    className="w-full bg-[#a020f0] text-sm rounded-3xl p-3 text-white font-semibold transition duration-200 hover:bg-[#757E7E]"
                  >
                    {loading ? <Spinner /> : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {edit && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center sm:min-h-screen md:px-4 pt-4 pb-10 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  top-20 md:top-0  sm:my-5 w-full sm:max-w-md sm:p-6  sm:align-middle">
              <div className="flex items-start justify-between ">
                <div className=" flex justify-between font-semibold py-2">
                  <p className="text-xs  text-gray-900 ">Edit Announcement</p>
                </div>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setEdit(false);
                  }}
                  type="button"
                  className="text-gray-900 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="default-modal"
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>

              <form onSubmit={handleSubmit(updateHandler)} className="mt-4">
                <div className="w-full my-2">
                  <label className="relative bg-white text-center py-2 w-44  rounded-full px-auto  z-10 block text-xs text-[#495454] font-normal">
                    Image Thumbnail (Picture)
                  </label>
                  <input
                    type="text"
                    {...register("fileUrl")}
                    className="hidden"
                  />
                  <MultiImageUploader setAllUploadedUrls={setAllUploadedUrls} />
                </div>
                <div className="mb-5">
                  <label className="relative bg-white w-16 ml-5 text-[#495454] rounded-full px-auto -mb-2 z-10 block text-xs text font-normal">
                    Title
                  </label>
                  <input
                    type="text"
                    required
                    {...register("title")}
                    className="py-3 px-5 border-[0.5px] bg-[#F6F8F8] border-[#a020f0] rounded-full w-full focus:outline-none"
                  />
                </div>

                <div className="mb-2">
                  <label className="relative bg-white w-16 ml-5 text-[#495454] rounded-full px-auto -mb-2 z-10 block text-xs text font-normal">
                    Content
                  </label>
                  <textarea
                    type="text"
                    required
                    {...register("description")}
                    rows={3}
                    className="py-3 text-sm px-5 border-[0.5px] bg-[#F6F8F8] border-[#a020f0] rounded-lg w-full focus:outline-none"
                  />
                </div>

                <div className="flex gap-x-2  w-full">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setEdit(false);
                      reset();
                    }}
                    className="w-full flex item-center text-sm justify-center rounded-3xl p-3 text-[#a020f0] border border-[#a020f0] font-bold"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    value="submit"
                    disabled={load || loadingUpload}
                    className="w-full bg-[#a020f0] text-sm rounded-3xl p-3 text-white font-semibold transition duration-200 hover:bg-[#757E7E]"
                  >
                    {load ? <Spinner /> : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      {deleteModal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-md sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-start justify-between ">
                <h3 className="text-[#000] text-xl font-semibold dark:text-white">
                  Confirm Announcement Delete
                </h3>
              </div>

              <p className="text-left py-5 text-sm text-[#141414]/70">
                Are you sure you want to delete this Announcement ?
              </p>
              <div className="flex gap-x-3 items-start justify-between">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setDeleteModal(false);
                  }}
                  className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#a020f0] bg-[#D6DDE8]  px-3 py-4 rounded-lg "
                >
                  No, Cancel
                </button>
                <button
                  onClick={() => {
                    const data = blogId;
                    deleteHandler(data);
                  }}
                  className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#CA0D0D]  px-3 py-4 rounded-lg "
                >
                  Yes, Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {deleteSucess && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-md sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-start justify-between ">
                <h3 className="text-[#000] text-xl font-semibold dark:text-white">
                  Announcement Deleted Successfully
                </h3>
              </div>

              <p className="text-left py-5 text-sm text-[#D3B61F]">
                You have successfully Deleted this Announcement
              </p>
              <div className="flex gap-x-3 items-start justify-between">
                <button
                  onClick={(e) => {
                    setDeleteSucess(false);
                  }}
                  className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#a020f0]  px-3 py-4 rounded-lg "
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Blog;
