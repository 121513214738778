import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import SEO from "../components/SEO";
import Loading from "../components/preLoaders/Loading";
import PageLoading from "../components/preLoaders/PageLoading";
import { useGetAllStaffsQuery } from "../Slices/apisSlice";

function Team() {
  const [staff, setStaff] = useState([]);
  const { data, isLoading, refetch, isError } = useGetAllStaffsQuery();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (data) {
      setStaff(data.data.results);
    }
  }, [data]);

  return (
    <>
      <SEO
        title="Team"
        description="The Best Place To Enroll Your Child/Ward."
        name="CHARISMABASE"
        type="description"
      />
      <div className=" bg-[#F5F5F5] py-10 lg:py-20 px-2 lg:px-24  flex flex-col  items-center  justify-center gap-x-1 ">
        <div className="flex items-center max-w-min gap-x-2 mx-auto">
          <div className="flex flex-col justify-end items-end space-y-1">
            <div className="w-10 h-0.5 rounded bg-[#a020f0] "></div>
            <div className="w-16 h-0.5 rounded bg-[#a020f0]"></div>
          </div>
          <h2 className="text-base text-[#a020f0] font-semibold  text-center md:text-xl uppercase mx-auto">
            Instructors
          </h2>
          <div className="flex flex-col space-y-1">
            <div className="w-10 h-0.5 rounded bg-[#a020f0]"></div>
            <div className="w-16 h-0.5 rounded bg-[#a020f0]"></div>
          </div>
        </div>
        <h2 className="text-sm text-[#000] font-semibold  text-center md:text-3xl mb-8 mx-auto">
          Expert Instructors
        </h2>
        {isLoading ? (
          <div className="py-5">
            <PageLoading />
          </div>
        ) : isError ? (
          <div className="pt-5">
            <Loading />
          </div>
        ) : (
          <motion.div
            initial={{ x: 20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="grid gap-3 grid-cols-1 md:grid-cols-4 "
          >
            <motion.div
              initial={{ y: -70, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.9 }}
              className=" "
            >
              <div className="overflow-hidden">
                <img
                  src="GCSSimg/team2.jpg"
                  alt="i"
                  className="rounded-t-xl w-full h-[25rem] object-cover hover:shadow-xl hover:transform hover:scale-105 duration-300"
                />
              </div>
              <div className="bg-[#F0FBFC] p-5 rounded-b-xl text-center">
                <p className="text-gray-900 font-bold text-sm md:text-base">
                  ANTHONY AMASOARIRI UGO UGO OHA 1 OF EKWERAZU
                </p>
                <p className="text-xs sm:text-sm text-gray-500 font-light">
                  DIRECTOR
                </p>
              </div>
            </motion.div>
            <motion.div
              initial={{ y: -80, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 1 }}
              className=" "
            >
              <div className="overflow-hidden">
                <img
                  src="GCSSimg/team1.jpg"
                  alt="i"
                  className="rounded-t-xl w-full h-[25rem] object-cover hover:shadow-xl hover:transform hover:scale-105 duration-300"
                />
              </div>
              <div className="bg-[#F0FBFC] p-5 rounded-b-xl text-center">
                <p className="text-gray-900 font-bold text-sm md:text-base">
                  UCHECHI KEVIN
                </p>
                <p className="text-xs sm:text-sm text-gray-500 pb-5 font-light">
                  ADMIN ACADEMICS
                </p>
              </div>
            </motion.div>
            <motion.div
              initial={{ y: -90, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 1 }}
              className=" "
            >
              <div className="overflow-hidden">
                <img
                  src="GCSSimg/team3.jpg"
                  alt="i"
                  className="rounded-t-xl w-full h-[25rem] object-cover hover:shadow-xl hover:transform hover:scale-105 duration-300"
                />
              </div>
              <div className="bg-[#F0FBFC] p-5 rounded-b-xl text-center">
                <p className="text-gray-900 font-bold uppercase text-sm md:text-base">
                  Mr Kingsley Esimbe
                </p>
                <p className="text-xs sm:text-sm text-gray-500 pb-5 font-light">
                  Secretary-Admin unit/P.R.O
                </p>
              </div>
            </motion.div>
            <motion.div
              initial={{ y: -100, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 1 }}
              className=" "
            >
              <div className="overflow-hidden">
                <img
                  src="GCSSimg/team4.jpg"
                  alt="i"
                  className="rounded-t-xl w-full h-[25rem] object-cover hover:shadow-xl hover:transform hover:scale-105 duration-300"
                />
              </div>
              <div className="bg-[#F0FBFC] p-5 rounded-b-xl text-center">
                <p className="text-gray-900 font-bold uppercase text-sm md:text-base">
                  Mrs. Amaechi Victoria
                </p>
                <p className="text-xs sm:text-sm text-gray-500 pb-2 font-light">
                  School Dean of Studies/ Computer teacher
                </p>
              </div>
            </motion.div>
            {staff
              .slice()
              .reverse()
              .map((item, index) => (
                <motion.div
                  initial={{ y: -70, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.9 }}
                  className=" "
                  key={index}
                >
                  <div className="overflow-hidden">
                    <img
                      src={item.fileUrl}
                      alt="i"
                      className="rounded-t-xl w-full h-[25rem] object-cover hover:shadow-xl hover:transform hover:scale-105 duration-300"
                    />
                  </div>
                  <div className="bg-[#F0FBFC] p-5 rounded-b-xl text-center">
                    <p className="text-gray-900 font-bold text-sm md:text-base uppercase">
                      {item.title}
                    </p>
                    <p className="text-xs sm:text-sm text-gray-500 font-light">
                      {item.description}
                    </p>
                  </div>
                </motion.div>
              ))}
          </motion.div>
        )}
      </div>
    </>
  );
}

export default Team;
