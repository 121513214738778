import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import MainLayout from "./components/layouts/MainLayout";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import Gallery from "./pages/Gallery";
import Team from "./pages/Team";


import Announcement from "./pages/Announcement";
import Login from "./pages/Login";
import AuthRoute from "./components/AuthRoute";
import DashboardLayout from "./components/layouts/DashboardLayout";
import Staffs from "./pages/DashboardScreen/Staffs";
import Index from "./pages/DashboardScreen/Index";
import Testimonials from "./pages/DashboardScreen/Testimonials";
import Testimonial from "./pages/Testimonial";
import Galleries from "./pages/DashboardScreen/Galleries";
import Settings from "./pages/DashboardScreen/Settings/Index";
import Blog from "./pages/DashboardScreen/Blog";
import Admission from "./pages/Admission";
import Curriculum from "./pages/Curriculum";
import Admissions from "./pages/DashboardScreen/Admissions";
import Curriculums from "./pages/DashboardScreen/Curriculum";
function App() {
  return (
    <div className="font-[Nunito]">
      <TawkMessengerReact
        propertyId="ca5f5d0ea202703a5bc7edb9fd3acdfee4e8bc20"
        widgetId="1i7rpafc0"
      />
      <Router>
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route path="/" element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="contact" element={<Contact />} />
            <Route path="gallery" element={<Gallery />} />
            <Route path="team" element={<Team />} />
            <Route path="testimonial" element={<Testimonial />} />
            <Route path="announcement" element={<Announcement />} />
            <Route path="curriculum" element={<Curriculum />} />
            <Route path="admission" element={<Admission />} />
          </Route>
          <Route path="manage" element={<Login />} />
          <Route path="" element={<AuthRoute />}>
            <Route path="dashboard" element={<DashboardLayout />}>
              <Route path="index" element={<Index />} />
              <Route path="testimonials" element={<Testimonials />} />
              <Route path="curriculums" element={<Curriculums />} />
              <Route path="admission" element={<Admissions />} />
              <Route path="gallery" element={<Galleries />} />
              <Route path="staffs" element={<Staffs />} />
              <Route path="settings" element={<Settings />} />
              <Route path="announcement" element={<Blog />} />
            </Route>
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
