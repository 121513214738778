import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SEO from "../components/SEO";
import TestimonySlide from "../components/TestimonySlide";
import { motion, AnimatePresence } from "framer-motion";
import MainSlide from "../components/MainSlide";

function Home() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const [more1, setMore1] = useState(false);
  const [more2, setMore2] = useState(false);
  const [more3, setMore3] = useState(false);

  return (
    <div className="bg-[#fff]  text-gray-600 ">
      <SEO
        title="GREAT CHARISMABASE STANDARD SCHOOL | Home"
        description="The Best Place To Enroll Your Child/Ward."
        name="CHARISMABASE"
        type="description"
      />
      {/* Hero ++++++++++++++++++++++++++ */}
      <MainSlide />

      {/* CTA ++++++++++++++++++++++++++ */}
      <section class="bg-white ">
        <div class="container py-10 mx-auto">
          <div class="grid grid-cols-1 gap-5 mt-8  md:grid-cols-2 lg:grid-cols-4">
            <div class="flex flex-col items-center p-6 space-y-3 text-center bg-[#F0FBFC] hover:bg-[#a020f0]/50 hover:text-[#fff] rounded-md hover:shadow-xl hover:transform hover:scale-105 duration-300 cursor-pointer">
              <i class="fa fa-3x fa-graduation-cap text-[#a020f0] mb-4"></i>

              <h1 class="text-lg font-bold  capitalize ">
                Skilled Instructors
              </h1>

              <p class=" text-sm ">
                Our staffs are dedicated to promoting high achievements for all
                students.
              </p>
            </div>

            <div class="flex flex-col items-center p-6 space-y-3 text-center bg-[#F0FBFC] hover:bg-[#a020f0]/50 hover:text-[#fff] rounded-md hover:shadow-xl hover:transform hover:scale-105 duration-300 cursor-pointer">
              <i class="fa fa-3x fa-book text-[#a020f0]  mb-4"></i>

              <h1 class="text-lg font-bold  capitalize ">Our Curriculum</h1>

              <p class=" text-sm ">
                Our curriculum is all round relevant and assessable for all
                through Infrastructure
              </p>
            </div>

            <div class="flex flex-col items-center p-6 space-y-3 text-center bg-[#F0FBFC] hover:bg-[#a020f0]/50 hover:text-[#fff] rounded-md hover:shadow-xl hover:transform hover:scale-105 duration-300 cursor-pointer">
              <i class="fa fa-3x fa-briefcase text-[#a020f0]  mb-4"></i>

              <h1 class="text-lg font-bold  capitalize ">Our Achievements</h1>

              <p class=" text-sm ">
                At Great Charismabase Standard Schools, we take pride in our
                students
              </p>
            </div>

            <div class="flex flex-col items-center p-6 space-y-3 text-center bg-[#F0FBFC] hover:bg-[#a020f0]/50 hover:text-[#fff] rounded-md hover:shadow-xl hover:transform hover:scale-105 duration-300 cursor-pointer">
              <i class="fa fa-3x fa-book-open text-[#a020f0]  mb-4"></i>

              <h1 class="text-lg font-bold  capitalize ">Past Events</h1>

              <p class=" text-sm ">
                we nurture our students in their social, moral, spiritual and
                ...
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* CTA +++++++++++++++++++++++++++++ */}

      <div className="container m-auto lg:flex md:gap-6 lg:items-center lg:gap-10 py-5 md:py-10 px-2 my-10 ">
        <div className="sm:w-[35rem]">
          <div className="overflow-hidden">
            <img
              src="GCSSimg/gcss1.jpg"
              alt="i"
              className=" w-full object-cover h-[28rem]"
            />
          </div>
        </div>
        <div className="md:7/12 lg:w-6/12">
          <div className="flex items-center max-w-min gap-x-2">
            <h2 className="text-base text-[#a020f0] w-28 font-semibold  text-center md:text-xl uppercase mx-auto">
              About Us
            </h2>
            <div className="flex flex-col space-y-1">
              <div className="w-10 h-0.5 rounded bg-[#a020f0]"></div>
              <div className="w-16 h-0.5 rounded bg-[#a020f0]"></div>
            </div>
          </div>
          <h2 className="text-sm text-[#000] font-bold  text-left md:text-4xl my-5 mx-auto leading-10">
            Welcome to Great Charismabase Standard School
          </h2>
          <p className=" font-light text-[#62747d]">
            Great Charismabase Standard Schools provides opportunities for all
            students to engage in all round education program. We have staff
            members that are dedicated to promoting high achievements for all
            students through...
          </p>
          <p className="mt-4 font-light text-[#62747d]">
            Our vision is to empower students to acquire...
          </p>
          <AnimatePresence>
            <motion.div
              initial={{ x: 20, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              // transition={{ duration: 2 }}
              exit={{ x: -300, opacity: 0 }}
              className="grid gap-10 grid-cols-1 md:grid-cols-2 mt-3 "
            >
              <div className="space-y-2">
                <p class="mb-0 font-light">
                  <i class="fa fa-arrow-right text-[#a020f0] me-2"></i>Skilled
                  Instructors
                </p>
                <p class="mb-0 font-light">
                  <i class="fa fa-arrow-right text-[#a020f0] me-2"></i>Hybrid
                  Security
                </p>
                <p class="mb-0 font-light">
                  <i class="fa fa-arrow-right text-[#a020f0] me-2"></i>Condusive
                  Learning Enviroment
                </p>
              </div>
              <div className="space-y-2">
                <p class="mb-0 font-light">
                  <i class="fa fa-arrow-right text-[#a020f0] me-2"></i>
                  Government Approved
                </p>
                <p class="mb-0 font-light">
                  <i class="fa fa-arrow-right text-[#a020f0] me-2"></i>24hrs
                  Power Supply
                </p>
                <p class="mb-0 font-light">
                  <i class="fa fa-arrow-right text-[#a020f0] me-2"></i>Hyper
                  Equipped Science Labs.
                </p>
              </div>
            </motion.div>
          </AnimatePresence>
          <div className=" flex gap-x-4 mt-5 items-center">
            <Link
              to="/about"
              className="w-[10rem] bg-[#a020f0]  text-xs lg:text-base py-3 text-center text-white  transition duration-200 hover:bg-[#757E7E]"
            >
              Read More
            </Link>
          </div>
        </div>
      </div>

      <div className="container  mx-auto mb-5">
        <div className="flex items-center max-w-min gap-x-2 mx-auto">
          <div className="flex flex-col justify-end items-end space-y-1">
            <div className="w-10 h-0.5 rounded bg-[#a020f0] "></div>
            <div className="w-16 h-0.5 rounded bg-[#a020f0]"></div>
          </div>
          <h2 className="text-base text-[#a020f0] font-semibold  text-center md:text-xl uppercase mx-auto">
            Categories
          </h2>
          <div className="flex flex-col space-y-1">
            <div className="w-10 h-0.5 rounded bg-[#a020f0]"></div>
            <div className="w-16 h-0.5 rounded bg-[#a020f0]"></div>
          </div>
        </div>
        <h2 className="text-sm text-[#000] font-semibold  text-center md:text-4xl mt-3 mb-8 mx-auto">
          Our Classes
        </h2>
        <div className="grid gap-3 grid-cols-1 md:grid-cols-5 ">
          <motion.div
            initial={{ y: -70, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.9 }}
            className="col-span-3"
          >
            <div className="relative flex items-end overflow-hidden ">
              <div className="overflow-hidden">
                <img
                  src="GCSSimg/Learners 13.jpg"
                  alt="i"
                  className=" w-full hover:shadow-xl hover:transform hover:scale-105 duration-300"
                />
              </div>
              <div className="absolute right-1 sm:py-1.5 px-3 bottom-0.5  bg-[#FFF]  cursor-pointer group text-center">
                <p className="text-gray-900 font-bold text-base md:text-xl">
                  Kindergerteen
                </p>
                <p className="text-sm  text-[#a020f0] font-light">
                  Day-care/Nursery
                </p>
              </div>
            </div>
            <motion.div
              initial={{ x: 20, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.5 }}
              className="grid gap-3 mt-3 grid-cols-1 md:grid-cols-2 "
            >
              <div className="relative flex items-end overflow-hidden">
                <div className="overflow-hidden">
                  <img
                    src="GCSSimg/Learners 1.jpg"
                    alt="i"
                    className=" w-full hover:shadow-xl hover:transform hover:scale-105 duration-300"
                  />
                </div>
                <div className="absolute right-1 sm:py-1.5 px-3 bottom-0.5  bg-[#FFF]  cursor-pointer group text-center">
                  <p className="text-gray-900 font-bold text-base md:text-xl">
                    Basic
                  </p>
                  <p className="text-sm  text-[#a020f0] font-light">
                    Basic 1-6
                  </p>
                </div>
              </div>
              <div className="relative flex items-end overflow-hidden">
                <div className="overflow-hidden">
                  <img
                    src="GCSSimg/Learners 18.jpg"
                    alt="i"
                    className=" w-full hover:shadow-xl hover:transform hover:scale-105 duration-300"
                  />
                </div>
                <div className="absolute right-1 sm:py-1.5 px-3 bottom-0.5  bg-[#FFF]  cursor-pointer group text-center">
                  <p className="text-gray-900 font-bold text-base md:text-xl">
                    Upper Basic
                  </p>
                  <p className="text-sm  text-[#a020f0] font-light">JS 1-3</p>
                </div>
              </div>
            </motion.div>
          </motion.div>
          <motion.div
            initial={{ y: -80, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 1 }}
            className="col-span-2 "
          >
            <div className="relative flex items-end overflow-hidden">
              <div className="overflow-hidden">
                <img
                  src="GCSSimg/Learners 4.jpg"
                  alt="i"
                  className=" w-full h-[51.5rem] object-cover hover:shadow-xl hover:transform hover:scale-105 duration-300"
                />
              </div>
              <div className="absolute right-1 sm:py-1.5 px-3 bottom-0.5  bg-[#FFF]  cursor-pointer group text-center">
                <p className="text-gray-900 font-bold text-base md:text-xl">
                  High School
                </p>
                <p className="text-sm  text-[#a020f0] font-light">SS 1-3</p>
              </div>
            </div>
          </motion.div>
        </div>
      </div>

      <div className="container  mx-auto my-5 lg:my-20">
        <div className="flex items-center max-w-min gap-x-2 mx-auto">
          <div className="flex flex-col justify-end items-end space-y-1">
            <div className="w-10 h-0.5 rounded bg-[#a020f0] "></div>
            <div className="w-16 h-0.5 rounded bg-[#a020f0]"></div>
          </div>
          <h2 className="text-base text-[#a020f0] font-semibold  text-center md:text-xl uppercase mx-auto">
            Activities
          </h2>
          <div className="flex flex-col space-y-1">
            <div className="w-10 h-0.5 rounded bg-[#a020f0]"></div>
            <div className="w-16 h-0.5 rounded bg-[#a020f0]"></div>
          </div>
        </div>
        <h2 className="text-sm text-[#000] font-semibold  text-center md:text-4xl mt-3 mb-8 mx-auto">
          Annual School Programs
        </h2>
        <div className="grid gap-10 grid-cols-1 md:grid-cols-3">
          <motion.div
            initial={{ y: -70, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.9 }}
            className=" "
          >
            <div className="overflow-hidden">
              <img
                src="GCSSimg/IMG-20240608-WA0021.jpg"
                alt="i"
                className=" w-full hover:shadow-xl hover:transform hover:scale-105 duration-300"
              />
            </div>
            <div className="bg-[#F0FBFC] p-5 rounded-b-xl text-center">
              <p className="text-gray-900 font-bold text-sm md:text-lg">
                ANNUAL SCHOOL GRADUATION <br /> CEREMONY
              </p>
            </div>
          </motion.div>
          <motion.div
            initial={{ y: -80, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 1 }}
            className=" "
          >
            <div className="overflow-hidden">
              <img
                src="GCSSimg/IMG-20240608-WA0035.jpg"
                alt="i"
                className=" w-full hover:shadow-xl hover:transform hover:scale-105 duration-300"
              />
            </div>
            <div className="bg-[#F0FBFC] p-5 rounded-b-xl text-center">
              <p className="text-gray-900 font-bold text-sm md:text-lg">
                INTER-HOUSE SPORTS <br /> COMPETITION
              </p>
            </div>
          </motion.div>
          <motion.div
            initial={{ y: -90, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 1 }}
            className=" "
          >
            <div className="overflow-hidden">
              <img
                src="GCSSimg/IMG-20240608-WA0033.jpg"
                alt="i"
                className=" w-full hover:shadow-xl hover:transform hover:scale-105 duration-300"
              />
            </div>
            <div className="bg-[#F0FBFC] p-5 rounded-b-xl text-center">
              <p className="text-gray-900 font-bold uppercase text-sm md:text-lg">
                CULTURAL DAY CELEBRATION / YEAR END PARTY
              </p>
            </div>
          </motion.div>
        </div>
      </div>

      <div className="container  mx-auto my-5 lg:my-10">
        <div className="container m-auto  bg-[#F0FBFC] py-10 px-5 lg:px-24  flex flex-col  items-center  justify-center  rounded-xl">
          <h2 className="text-xs text-[#000]  italic text-center md:text-4xl ">
            “Education is our passport to the future, for tomorrow belongs only
            to the people who prepare for it today.”
          </h2>
          <h2 className="text-sm text-[#000] font-bold md:text-lg pt-5 underline">
            Malcolm X
          </h2>
        </div>
      </div>

      {/* social */}
      <div className=" bg-[#F5F5F5] py-10 lg:py-20 px-2 lg:px-24  flex flex-col  items-center  justify-center gap-x-1 ">
        <div className="flex items-center max-w-min gap-x-2 mx-auto">
          <div className="flex flex-col justify-end items-end space-y-1">
            <div className="w-10 h-0.5 rounded bg-[#a020f0] "></div>
            <div className="w-16 h-0.5 rounded bg-[#a020f0]"></div>
          </div>
          <h2 className="text-base text-[#a020f0] font-semibold  text-center md:text-xl uppercase mx-auto">
            Instructors
          </h2>
          <div className="flex flex-col space-y-1">
            <div className="w-10 h-0.5 rounded bg-[#a020f0]"></div>
            <div className="w-16 h-0.5 rounded bg-[#a020f0]"></div>
          </div>
        </div>
        <h2 className="text-sm text-[#000] font-semibold  text-center md:text-3xl mb-8 mx-auto">
          Expert Instructors
        </h2>
        <motion.div
          initial={{ x: 20, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="grid gap-3 grid-cols-1 md:grid-cols-4 "
        >
          <motion.div
            initial={{ y: -70, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.9 }}
            className=" "
          >
            <div className="overflow-hidden">
              <img
                src="GCSSimg/team2.jpg"
                alt="i"
                className="rounded-t-xl w-full h-[25rem] object-cover hover:shadow-xl hover:transform hover:scale-105 duration-300"
              />
            </div>
            <div className="bg-[#F0FBFC] p-5 rounded-b-xl text-center">
              <p className="text-gray-900 font-bold text-sm md:text-base">
                ANTHONY AMASOARIRI UGO UGO OHA 1 OF EKWERAZU
              </p>
              <p className="text-xs sm:text-sm text-gray-500 font-light">
                DIRECTOR
              </p>
            </div>
          </motion.div>
          <motion.div
            initial={{ y: -80, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 1 }}
            className=" "
          >
            <div className="overflow-hidden">
              <img
                src="GCSSimg/team1.jpg"
                alt="i"
                className="rounded-t-xl w-full h-[25rem] object-cover hover:shadow-xl hover:transform hover:scale-105 duration-300"
              />
            </div>
            <div className="bg-[#F0FBFC] p-5 rounded-b-xl text-center">
              <p className="text-gray-900 font-bold text-sm md:text-base">
                UCHECHI KEVIN
              </p>
              <p className="text-xs sm:text-sm text-gray-500 pb-5 font-light">
                ADMIN ACADEMICS
              </p>
            </div>
          </motion.div>
          <motion.div
            initial={{ y: -90, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 1 }}
            className=" "
          >
            <div className="overflow-hidden">
              <img
                src="GCSSimg/team3.jpg"
                alt="i"
                className="rounded-t-xl w-full h-[25rem] object-cover hover:shadow-xl hover:transform hover:scale-105 duration-300"
              />
            </div>
            <div className="bg-[#F0FBFC] p-5 rounded-b-xl text-center">
              <p className="text-gray-900 font-bold uppercase text-sm md:text-base">
                Mr Kingsley Esimbe
              </p>
              <p className="text-xs sm:text-sm text-gray-500 pb-5 font-light">
                Secretary-Admin unit/P.R.O
              </p>
            </div>
          </motion.div>
          <motion.div
            initial={{ y: -100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 1 }}
            className=" "
          >
            <div className="overflow-hidden">
              <img
                src="GCSSimg/team4.jpg"
                alt="i"
                className="rounded-t-xl w-full h-[25rem] object-cover hover:shadow-xl hover:transform hover:scale-105 duration-300"
              />
            </div>
            <div className="bg-[#F0FBFC] p-5 rounded-b-xl text-center">
              <p className="text-gray-900 font-bold uppercase text-sm md:text-base">
                Mrs. Amaechi Victoria
              </p>
              <p className="text-xs sm:text-sm text-gray-500 pb-2 font-light">
                School Dean of Studies/ Computer teacher
              </p>
            </div>
          </motion.div>
        </motion.div>
        <Link
          to="/team"
          className="w-[13rem] bg-[#a020f0] text-lg   py-3 text-center text-[#fff]  transition duration-200 hover:bg-[#41d1d1] mx-auto hover:text-white my-5 lg:mt-10"
        >
          See More<i class="fa fa-arrow-right ms-3"></i>
        </Link>
      </div>

      <div className=" bg-[#fff] py-10 lg:py-20 px-2  ">
        {/* Testimonies *********************************************/}
        <div className="flex items-center max-w-min gap-x-2 mx-auto">
          <div className="flex flex-col justify-end items-end space-y-1">
            <div className="w-10 h-0.5 rounded bg-[#a020f0] "></div>
            <div className="w-16 h-0.5 rounded bg-[#a020f0]"></div>
          </div>
          <h2 className="text-base text-[#a020f0] font-semibold  text-center md:text-xl uppercase mx-auto">
            Testimonial
          </h2>
          <div className="flex flex-col space-y-1">
            <div className="w-10 h-0.5 rounded bg-[#a020f0]"></div>
            <div className="w-16 h-0.5 rounded bg-[#a020f0]"></div>
          </div>
        </div>
        <h2 className="text-lg text-[#000] font-bold  text-center md:text-4xl my-5 lg:w-[35rem] mx-auto">
          Our Parents Say!
        </h2>
        <h2 className="mx-auto text-[10px] text-[#36474F] text-center font-semibold md:text-sm md:w-[44rem] lg:w-[44rem] flex items-center justify-center gap-x-2">
          <img
            className="object-cover object-center w-auto h-5 md:w-auto md:h-9 "
            src="../Frame 41.png"
            alt=""
          />{" "}
          over 100+ Parents trust us
        </h2>
        <section className="mx-auto  justify-center md:space-x-2 my-5 lg:my-10 ">
          <div className="w-full ">
            <TestimonySlide />
          </div>
        </section>
      </div>
    </div>
  );
}

export default Home;
