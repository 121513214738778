import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import SEO from "../components/SEO";
import { motion, AnimatePresence } from "framer-motion";
function About() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <SEO
        title="About Us"
        description="The Best Place To Enroll Your Child/Ward."
        name="CHARISMABASE"
        type="description"
      />
      <div className=" text-gray-600">
        <div className="relative flex items-center h-[15rem] lg:h-[18rem] overflow-hidden">
          <img
            src="GCSSimg/Learners 10.jpg"
            alt="i"
            className="w-full bg-bottom bg-cover"
          />

          <div className="absolute  bg-[#181d38]/70  cursor-pointer h-[15rem] lg:h-[25rem] w-full text-center">
            <div className="flex items-center justify-center w-full h-full  ">
              <motion.div
                initial={{ x: 300, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: -300, opacity: 0 }}
                className="text-center "
              >
                <h1 className="text-2xl font-bold text-white  lg:text-7xl">
                  About Us
                </h1>
              </motion.div>
            </div>
          </div>
        </div>
      </div>

      {/* CTA ++++++++++++++++++++++++++ */}
      <section class="bg-white ">
        <div class="container py-10 mx-auto">
          <div class="grid grid-cols-1 gap-5 mt-8  md:grid-cols-2 lg:grid-cols-4">
            <div class="flex flex-col items-center p-6 space-y-3 text-center bg-[#F0FBFC] hover:bg-[#a020f0]/50 hover:text-[#fff] rounded-md hover:shadow-xl hover:transform hover:scale-105 duration-300 cursor-pointer">
              <i class="fa fa-3x fa-graduation-cap text-[#a020f0] mb-4"></i>

              <h1 class="text-lg font-bold  capitalize ">
                Skilled Instructors
              </h1>

              <p class=" text-sm ">
                Our staffs are dedicated to promoting high achievements for all
                students.
              </p>
            </div>

            <div class="flex flex-col items-center p-6 space-y-3 text-center bg-[#F0FBFC] hover:bg-[#a020f0]/50 hover:text-[#fff] rounded-md hover:shadow-xl hover:transform hover:scale-105 duration-300 cursor-pointer">
              <i class="fa fa-3x fa-book text-[#a020f0]  mb-4"></i>

              <h1 class="text-lg font-bold  capitalize ">Our Curriculum</h1>

              <p class=" text-sm ">
                Our curriculum is all round relevant and assessable for all
                through Infrastructure
              </p>
            </div>

            <div class="flex flex-col items-center p-6 space-y-3 text-center bg-[#F0FBFC] hover:bg-[#a020f0]/50 hover:text-[#fff] rounded-md hover:shadow-xl hover:transform hover:scale-105 duration-300 cursor-pointer">
              <i class="fa fa-3x fa-briefcase text-[#a020f0]  mb-4"></i>

              <h1 class="text-lg font-bold  capitalize ">Our Achievements</h1>

              <p class=" text-sm ">
                At Great Charismabase Standard Schools, we take pride in our
                students
              </p>
            </div>

            <div class="flex flex-col items-center p-6 space-y-3 text-center bg-[#F0FBFC] hover:bg-[#a020f0]/50 hover:text-[#fff] rounded-md hover:shadow-xl hover:transform hover:scale-105 duration-300 cursor-pointer">
              <i class="fa fa-3x fa-book-open text-[#a020f0]  mb-4"></i>

              <h1 class="text-lg font-bold  capitalize ">Past Events</h1>

              <p class=" text-sm ">
                we nurture our students in their social, moral, spiritual and
                ...
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* Hero ++++++++++++++++++++++++++ */}
      <div className="container m-auto  text-gray-600 my-10">
        <div className="space-y-6 md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12 px-2 lg:px-16">
          <div className="md:7/12 lg:w-6/12">
            <p className="mt-6 font-light text-[#62747d]">
              Great Charismabase Standard Schools located at Ebenezer by
              Deeper-Life Umuoru- Umuode Village in Osisioma Ngwa L.G.A. Aba,
              Abia State, was founded in the year 2014 (September 22nd) with
              Nursery and Primary Sections with dedicated and God-fearing
              teachers. Two years later, the Secondary Section came into
              existence (September 12th, 2016).
            </p>
            <p className="mt-3 font-light text-[#62747d]">
              We are responsible, accountable, respectful, effective, efficient
              and public stewards. We promote honesty, integrity and openness in
              all we do. Therefore, we nurture our students in their social,
              moral, spiritual and academic development by providing them with
              high quality education.
            </p>
            <div className=" flex gap-x-4 mt-5 items-center">
              <button className="w-[13rem] bg-[#a020f0] text-lg   py-3 text-center text-[#fff]  transition duration-200 hover:bg-[#41d1d1]  hover:text-white ">
                Enroll Now<i class="fa fa-arrow-right ms-3"></i>
              </button>
            </div>
          </div>

          <div className="sm:w-[28rem]">
            <img src="GCSSimg/gcss1.jpg" className="h-[22rem] w-full" alt="i" />
          </div>
        </div>
      </div>
      <div className="container m-auto  text-gray-600 my-10">
        <div className="space-y-6 md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12 px-2 lg:px-16">
          <div className="flex items-center gap-x-4 px-5  bg-[#F9F9F9] md:w-6/12 rounded-xl">
            <img src="../mission.png" className="h-28" alt="i" />
            <div>
              <h1 className="text-lg mt-5 sm:text-xl font-semibold text-gray-800">
                Our Mission
              </h1>
              <p className="my-3  text-sm font-light text-[#62747d]">
                Great Charismabase Standard Schools provides opportunities for
                all students to engage in all round education program. We have
                staff members that are dedicated to promoting high achievements
                for all students through: Infrastructure and technology that
                supports 21st Century Learning.
              </p>
            </div>
          </div>

          <div className="flex items-center  gap-x-4 px-5  bg-[#F9F9F9] md:w-6/12 rounded-xl">
            <img src="../vision.png" className="h-28" alt="i" />
            <div>
              <h1 className="text-lg mt-5 sm:text-xl font-semibold text-gray-800">
                Our Vision
              </h1>
              <p className="my-3 text-sm font-light text-[#62747d]">
                Our vision is to empower students to acquire, demonstrate, and
                articulate value, knowledge and skills; to have competitive and
                highly productive citizens in our society.
              </p>
              <p className="mt-1 text-sm font-light text-[#62747d]">
                <br />
                <br />
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* What we offer +++++++++++++++++++++++++++ */}
      <div className="px-2 bg-[#F9F9F9]  mx-auto py-20">
        <div className="flex items-center max-w-min gap-x-2 mx-auto">
          <div className="flex flex-col justify-end items-end space-y-1">
            <div className="w-10 h-0.5 rounded bg-[#a020f0] "></div>
            <div className="w-16 h-0.5 rounded bg-[#a020f0]"></div>
          </div>
          <h2 className="text-base text-[#a020f0] font-semibold  text-center md:text-xl uppercase mx-auto">
            ACHIEVEMENTS
          </h2>
          <div className="flex flex-col space-y-1">
            <div className="w-10 h-0.5 rounded bg-[#a020f0]"></div>
            <div className="w-16 h-0.5 rounded bg-[#a020f0]"></div>
          </div>
        </div>

        <h2 className="text-[10px] text-[#000] text-center font-light md:text-sm md:w-[40rem] lg:w-[44rem] mx-auto">
          At Great Charismabase Standard Schools, we take pride in our students'
          accomplishments and milestones. Here are some of our notable
          achievements:
        </h2>
        <div className="mx-auto container px-2 lg:px-16 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 my-12">
          <div className="bg-[#fff] p-5 rounded-xl ">
            <h1 className="text-xs sm:text-base font-semibold text-gray-800">
              Examination Accreditation (2017)
            </h1>
            <p className="text-[#36474F] font-light text-xs">
              Approved center for First School Leaving Examination and Federal
              Examinations
            </p>
          </div>
          <div className="bg-[#fff] p-5 rounded-xl ">
            <h1 className="text-xs sm:text-base font-semibold text-gray-800">
              National Art Exhibition (2019)
            </h1>
            <p className="text-[#36474F] font-light text-xs">
              Represented Abia state at the national level and Won third place
              nationally
            </p>
          </div>
          <div className="bg-[#fff] p-5 rounded-xl ">
            <h1 className="text-xs sm:text-base font-semibold text-gray-800">
              WASSCE Approved Center (2020)
            </h1>
            <p className="text-[#36474F] font-light text-xs">
              Established as an approved center for West African Senior School
              Certificate Examination (WASSCE) on February 4th, 2020
            </p>
          </div>
          <div className="bg-[#fff] p-5 rounded-xl ">
            <h1 className="text-xs sm:text-base font-semibold text-gray-800">
              National Creative Writing Competition (2023)
            </h1>
            <p className="text-[#36474F] font-light text-xs">
              Secured second position in Abia state
            </p>
          </div>
          <div className="bg-[#fff] p-5 rounded-xl ">
            <h1 className="text-xs sm:text-base font-semibold text-gray-800">
              Mathematics and Science Ambassadorship (2023)
            </h1>
            <p className="text-[#36474F] font-light text-xs">
              Awarded certificates to outstanding science and art students
            </p>
          </div>
          <div className="bg-[#fff] p-5 rounded-xl ">
            <h1 className="text-xs sm:text-base font-semibold text-gray-800">
              Career Hunt Programme (2024)
            </h1>
            <p className="text-[#36474F] font-light text-xs">
              Successfully hosted our inaugural career hunt program
            </p>
          </div>
          <div className="bg-[#fff] p-5 rounded-xl ">
            <h1 className="text-xs sm:text-base font-semibold text-gray-800">
              Campus Expansion (2024)
            </h1>
            <p className="text-[#36474F] font-light text-xs">
              Acquired an approved site for the establishment of our Campus 2
            </p>
          </div>
        </div>
      </div>

      <div className=" bg-[#F5F5F5] py-10 lg:py-20 px-2 lg:px-24  flex flex-col  items-center  justify-center gap-x-1 ">
        <div className="flex items-center max-w-min gap-x-2 mx-auto">
          <div className="flex flex-col justify-end items-end space-y-1">
            <div className="w-10 h-0.5 rounded bg-[#a020f0] "></div>
            <div className="w-16 h-0.5 rounded bg-[#a020f0]"></div>
          </div>
          <h2 className="text-base text-[#a020f0] font-semibold  text-center md:text-xl uppercase mx-auto">
            Instructors
          </h2>
          <div className="flex flex-col space-y-1">
            <div className="w-10 h-0.5 rounded bg-[#a020f0]"></div>
            <div className="w-16 h-0.5 rounded bg-[#a020f0]"></div>
          </div>
        </div>
        <h2 className="text-sm text-[#000] font-semibold  text-center md:text-3xl mb-8 mx-auto">
          Expert Instructors
        </h2>
        <motion.div
          initial={{ x: 20, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="grid gap-3 grid-cols-1 md:grid-cols-4 "
        >
          <motion.div
            initial={{ y: -70, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.9 }}
            className=" "
          >
            <div className="overflow-hidden">
              <img
                src="GCSSimg/team2.jpg"
                alt="i"
                className="rounded-t-xl w-full h-[25rem] object-cover hover:shadow-xl hover:transform hover:scale-105 duration-300"
              />
            </div>
            <div className="bg-[#F0FBFC] p-5 rounded-b-xl text-center">
              <p className="text-gray-900 font-bold text-sm md:text-base">
                ANTHONY AMASOARIRI UGO UGO OHA 1 OF EKWERAZU
              </p>
              <p className="text-xs sm:text-sm text-gray-500 font-light">
                DIRECTOR
              </p>
            </div>
          </motion.div>
          <motion.div
            initial={{ y: -80, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 1 }}
            className=" "
          >
            <div className="overflow-hidden">
              <img
                src="GCSSimg/team1.jpg"
                alt="i"
                className="rounded-t-xl w-full h-[25rem] object-cover hover:shadow-xl hover:transform hover:scale-105 duration-300"
              />
            </div>
            <div className="bg-[#F0FBFC] p-5 rounded-b-xl text-center">
              <p className="text-gray-900 font-bold text-sm md:text-base">
                UCHECHI KEVIN
              </p>
              <p className="text-xs sm:text-sm text-gray-500 pb-5 font-light">
                ADMIN ACADEMICS
              </p>
            </div>
          </motion.div>
          <motion.div
            initial={{ y: -90, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 1 }}
            className=" "
          >
            <div className="overflow-hidden">
              <img
                src="GCSSimg/team3.jpg"
                alt="i"
                className="rounded-t-xl w-full h-[25rem] object-cover hover:shadow-xl hover:transform hover:scale-105 duration-300"
              />
            </div>
            <div className="bg-[#F0FBFC] p-5 rounded-b-xl text-center">
              <p className="text-gray-900 font-bold uppercase text-sm md:text-base">
                Mr Kingsley Esimbe
              </p>
              <p className="text-xs sm:text-sm text-gray-500 pb-5 font-light">
                Secretary-Admin unit/P.R.O
              </p>
            </div>
          </motion.div>
          <motion.div
            initial={{ y: -100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 1 }}
            className=" "
          >
            <div className="overflow-hidden">
              <img
                src="GCSSimg/team4.jpg"
                alt="i"
                className="rounded-t-xl w-full h-[25rem] object-cover hover:shadow-xl hover:transform hover:scale-105 duration-300"
              />
            </div>
            <div className="bg-[#F0FBFC] p-5 rounded-b-xl text-center">
              <p className="text-gray-900 font-bold uppercase text-sm md:text-base">
                Mrs. Amaechi Victoria
              </p>
              <p className="text-xs sm:text-sm text-gray-500 pb-2 font-light">
                School Dean of Studies/ Computer teacher
              </p>
            </div>
          </motion.div>
        </motion.div>
        <Link
          to="/team"
          className="w-[13rem] bg-[#a020f0] text-lg   py-3 text-center text-[#fff]  transition duration-200 hover:bg-[#41d1d1] mx-auto hover:text-white my-5 lg:mt-10"
        >
          See More<i class="fa fa-arrow-right ms-3"></i>
        </Link>
      </div>
    </div>
  );
}

export default About;
