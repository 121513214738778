import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <div className=" bg-[#181d38]">
        <div className="container  pt-10 pb-16 lg:py-16 mx-auto px-4  md:px- text-white  md:mb-0   ">
          <motion.div
            initial={{ x: 20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="grid grid-cols-1 lg:gap-8 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-7"
          >
            <div className="my-5 sm:my-0">
              <p className=" text-lg font-bold mb-3">Quick Link</p>
              <div className="flex flex-col items-start  space-y-2">
                <Link
                  to="/about"
                  className="text-gray-100 transition-colors duration-300 flex gap-x-2 items-center  hover:text-gray-300"
                >
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L6 6L1 11"
                      stroke="currentColor"
                      stroke-width="2"
                    />
                  </svg>{" "}
                  About us
                </Link>

                <Link
                  to="/testimonials"
                  className="text-gray-100 transition-colors duration-300 flex gap-x-2 items-center hover:text-gray-300"
                >
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L6 6L1 11"
                      stroke="currentColor"
                      stroke-width="2"
                    />
                  </svg>{" "}
                  Testimonials
                </Link>

                <Link
                  to="/team"
                  className="text-gray-100 transition-colors duration-300 flex gap-x-2 items-center hover:text-gray-300"
                >
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L6 6L1 11"
                      stroke="currentColor"
                      stroke-width="2"
                    />
                  </svg>{" "}
                  Team
                </Link>
              </div>
            </div>
            <div className="items-start col-span-2">
              <p className=" text-lg font-bold mb-3">Contact</p>
              <div className="flex flex-col items-start  space-y-2">
                <p className="">
                  <i className="fa fa-map-marker-alt me-3"></i>Ebenezer by
                  Deeper Life Umuoru Umuode Village in Osisioma Ngwa L.G.A. Aba,
                  Abia State
                </p>

                <p className="mb-2">
                  <i className="fa fa-phone-alt me-3"></i>+234 803 131 591
                </p>
                <p className="mb-2">
                  <i className="fa fa-envelope me-3"></i>
                  greatcharismaschools@gmail.com
                </p>

                <div className="lg:mb-0 mb-6 flex gap-x-2 justify-between">
                  <a
                    href="https://www.facebook.com/profile.php?id=61556186981965&mibextid=kFxxJD"
                    className="bg-gray-900/20 text-lightBlue-400  font-normal h-10 w-10 py-3  text-center items-center justify-center align-center rounded-full outline-none focus:outline-none border"
                  >
                    <svg
                      width="38"
                      height="18"
                      viewBox="0 0 10 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.37296 18V9.78936H9.26765L9.70104 6.5895H6.37288V4.54653C6.37288 3.6201 6.64305 2.98879 8.0385 2.98879L9.81819 2.98799V0.126072C9.51038 0.0871459 8.45386 0 7.22488 0C4.65886 0 2.90212 1.49118 2.90212 4.22972V6.5895H0V9.78936H2.90212V17.9999H6.37296V18Z"
                        fill="white"
                      />
                    </svg>
                  </a>
                  <a
                    href="##"
                    className="bg-gray-900/20 text-lightBlue-600  font-normal h-10 w-10  py-2 items-center justify-center align-center rounded-full outline-none focus:outline-none border"
                  >
                    <svg
                      className="w-10 h-5"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"></path>
                    </svg>
                  </a>
                  <a
                    href="##"
                    className="bg-gray-900/20 text-pink-400  font-normal h-10 w-10 py-2.5 items-center justify-center align-center rounded-full outline-none focus:outline-none border"
                  >
                    <svg
                      width="38"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 1.99134C0 1.41413 0.202708 0.937939 0.608108 0.56277C1.01351 0.187584 1.54055 0 2.18919 0C2.82626 0 3.34169 0.184693 3.73552 0.554112C4.14092 0.935065 4.34363 1.43145 4.34363 2.04329C4.34363 2.5974 4.14672 3.05915 3.7529 3.42857C3.3475 3.80952 2.81467 4 2.15444 4H2.13707C1.49999 4 0.984562 3.80952 0.590734 3.42857C0.196905 3.04762 0 2.56854 0 1.99134ZM0.225869 17.1429V5.57576H4.08301V17.1429H0.225869ZM6.22008 17.1429H10.0772V10.684C10.0772 10.2799 10.1236 9.96824 10.2162 9.74891C10.3784 9.35641 10.6245 9.02452 10.9546 8.75324C11.2847 8.48195 11.6988 8.34632 12.1969 8.34632C13.4942 8.34632 14.1429 9.21788 14.1429 10.961V17.1429H18V10.5108C18 8.8023 17.5946 7.50649 16.7838 6.62337C15.973 5.74026 14.9015 5.2987 13.5695 5.2987C12.0753 5.2987 10.9112 5.93939 10.0772 7.22078V7.25541H10.0598L10.0772 7.22078V5.57576H6.22008C6.24324 5.94516 6.25483 7.09378 6.25483 9.02164C6.25483 10.9495 6.24324 13.6565 6.22008 17.1429Z"
                        fill="white"
                      />
                    </svg>
                  </a>
                  <a
                    href="https://www.instagram.com/invites/contact/?i=3rbdzvh40kgv&utm_content=utq4b6p"
                    className="bg-gray-900/20 text-blueGray-800  font-normal h-10 w-10 py-2.5  items-center justify-center align-center rounded-full outline-none focus:outline-none border"
                  >
                    <svg
                      width="38"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0 9C0 5.40486 0 3.60729 0.856692 2.31255C1.23925 1.73439 1.73439 1.23925 2.31255 0.856692C3.60729 0 5.40486 0 9 0C12.5951 0 14.3927 0 15.6874 0.856692C16.2656 1.23925 16.7608 1.73439 17.1433 2.31255C18 3.60729 18 5.40486 18 9C18 12.5951 18 14.3927 17.1433 15.6874C16.7608 16.2656 16.2656 16.7608 15.6874 17.1433C14.3927 18 12.5951 18 9 18C5.40486 18 3.60729 18 2.31255 17.1433C1.73439 16.7608 1.23925 16.2656 0.856692 15.6874C0 14.3927 0 12.5951 0 9ZM13.6593 9.00023C13.6593 11.5735 11.5732 13.6596 8.99984 13.6596C6.42652 13.6596 4.34043 11.5735 4.34043 9.00023C4.34043 6.42691 6.42652 4.34082 8.99984 4.34082C11.5732 4.34082 13.6593 6.42691 13.6593 9.00023ZM8.99984 12.0832C10.7025 12.0832 12.0828 10.7029 12.0828 9.00023C12.0828 7.29753 10.7025 5.91722 8.99984 5.91722C7.29714 5.91722 5.91684 7.29753 5.91684 9.00023C5.91684 10.7029 7.29714 12.0832 8.99984 12.0832ZM13.8433 5.20155C14.4479 5.20155 14.9381 4.71138 14.9381 4.10673C14.9381 3.50207 14.4479 3.0119 13.8433 3.0119C13.2386 3.0119 12.7485 3.50207 12.7485 4.10673C12.7485 4.71138 13.2386 5.20155 13.8433 5.20155Z"
                        fill="white"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>

            <div className="items-start col-span-2 my-5 sm:my-0">
              <p className=" text-lg font-bold mb-3">Gallery</p>
              <div className="mt-3 w-[15rem]">
                <Link to="/galary">
                  <motion.div
                    initial={{ x: 20, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    className="grid gap-3 grid-cols-3 "
                  >
                    <img
                      className="border-4 h-[4.5rem] w-[5rem]"
                      src="GCSSimg/Learners 7.jpg"
                      alt=""
                    />
                    <img
                      className="border-4 h-[4.5rem] w-[5rem]"
                      src="GCSSimg/Learners 6.jpg"
                      alt=""
                    />

                    <img
                      className="border-4 h-[4.5rem] w-[5rem]"
                      src="GCSSimg/Learners 4.jpg"
                      alt=""
                    />
                    <img
                      className="border-4 h-[4.5rem] w-[5rem]"
                      src="GCSSimg/Learners 3.jpg"
                      alt=""
                    />
                    <img
                      className="border-4 h-[4.5rem] w-[5rem]"
                      src="GCSSimg/Learners 2.jpg"
                      alt=""
                    />
                    <img
                      className="border-4 h-[4.5rem] w-[5rem]"
                      src="GCSSimg/Learners 5.jpg"
                      alt=""
                    />
                  </motion.div>
                </Link>
              </div>
            </div>
            <div className="items-start  mb-2 ">
              <p className=" text-base font-bold mb-3">Newsletter</p>
              <p className="w-[16rem] ">
                Get more info from us via our Newsletter.
              </p>
              <div className="flex w-[17rem]  lg:w-[21rem] my-2">
                <input
                  // value={email}
                  type="email"
                  placeholder="Email Address"
                  // onChange={(e) => {
                  //   setEmail(e.target.value);
                  // }}
                  className="block w-full  placeholder-gray-500 sm:placeholder-gray-700/70 rounded-xl border border-gray-200 bg-white px-2.5 text-gray-700 text-xs  focus:outline-none required"
                />
                <button
                  className="bg-[#a020f0] py-auto -ml-9 rounded-r-xl "
                  // onClick={waitingListHandler}
                >
                  <svg
                    width="60"
                    height="54"
                    viewBox="0 0 60 54"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.5 0.5H52C56.1421 0.5 59.5 3.85786 59.5 8V46C59.5 50.1421 56.1421 53.5 52 53.5H0.5V0.5Z"
                      stroke="white"
                    />
                    <mask
                      id="mask0_2409_316"
                      maskUnits="userSpaceOnUse"
                      x="18"
                      y="12"
                      width="24"
                      height="24"
                    >
                      <rect
                        x="18"
                        y="12"
                        width="24"
                        height="24"
                        fill="#D9D9D9"
                      />
                    </mask>
                    <g mask="url(#mask0_2409_316)">
                      <path
                        d="M34.175 28.125H22V25.875H34.175L28.575 19.575L30 18L38 27L30 36L28.575 34.425L34.175 28.125Z"
                        fill="white"
                      />
                    </g>
                  </svg>
                </button>
              </div>
            </div>
          </motion.div>
          <div className="flex pt-5 md:pt-10 justify-between">
            <hr className="w-screen  " />
            <p className="-mt-2 text-xs sm:text-sm w-[90rem] sm:w-[60rem] md:w-[60rem] lg:w-[70rem] xl:w-[70rem] text-center ">
              © {currentYear} greatcharismaschools. All rights reserved.
            </p>
            <hr className="w-screen  " />
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
