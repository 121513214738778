import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import SEO from "../components/SEO";
import Loading from "../components/preLoaders/Loading";
import PageLoading from "../components/preLoaders/PageLoading";
import { useGetAllAdmissionDocQuery } from "../Slices/apisSlice";

function Admission() {
  const [doc, setDocs] = useState([]);
  const { data, isLoading, refetch, isError } = useGetAllAdmissionDocQuery();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (data) {
      setDocs(data.data.results);
    }
  }, [data]);

  return (
    <>
      <SEO
        title="Admission"
        description="The Best Place To Enroll Your Child/Ward."
        name="CHARISMABASE"
        type="description"
      />
      <div className=" bg-[#FFF] py-10 lg:py-20 px-2 lg:px-24  flex flex-col  items-center  justify-center gap-x-1 ">
        <div className="flex items-center max-w-min gap-x-2 mx-auto">
          <div className="flex flex-col justify-end items-end space-y-1">
            <div className="w-10 h-0.5 rounded bg-[#a020f0] "></div>
            <div className="w-16 h-0.5 rounded bg-[#a020f0]"></div>
          </div>
          <h2 className="text-base text-[#a020f0] font-semibold  text-center md:text-xl uppercase mx-auto">
            Admissions
          </h2>
          <div className="flex flex-col space-y-1">
            <div className="w-10 h-0.5 rounded bg-[#a020f0]"></div>
            <div className="w-16 h-0.5 rounded bg-[#a020f0]"></div>
          </div>
        </div>

        {isLoading ? (
          <div className="py-5">
            <PageLoading />
          </div>
        ) : isError ? (
          <div className="py-5">
            <Loading />
          </div>
        ) : (
          <motion.div
            initial={{ x: 20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="grid gap-5 grid-cols-1 md:grid-cols-2 mt-10"
          >
            {doc.reverse().map((item, index) => (
              <motion.a
                initial={{ y: -70, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.9 }}
                className="shadow "
                key={index}
                href={item?.fileUrl[0]}
                target="_blank"
              >
                <div className="flex justify-center">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="text-[#a020f0] w-[8rem] h-[8rem] lg:w-[20rem] lg:h-[20rem]"
                  >
                    <path
                      d="M17.5 2.5H7.5C6.83696 2.5 6.20107 2.76339 5.73223 3.23223C5.26339 3.70107 5 4.33696 5 5V25C5 25.663 5.26339 26.2989 5.73223 26.7678C6.20107 27.2366 6.83696 27.5 7.5 27.5H22.5C23.163 27.5 23.7989 27.2366 24.2678 26.7678C24.7366 26.2989 25 25.663 25 25V10L17.5 2.5Z"
                      stroke="CurrentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M17.5 2.5V10H25"
                      stroke="CurrentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M20 16.25H10"
                      stroke="CurrentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M20 21.25H10"
                      stroke="CurrentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12.5 11.25H11.25H10"
                      stroke="CurrentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div className="p-4 bg-[#F0FBFC]">
                  <div className="flex items-center gap-x-2">
                    <h1 className="text-xs sm:text-sm font-semibold text-[#a020f0] uppercase">
                      {item.title}
                    </h1>
                  </div>
                  <h1 className="text-gray-900 text-base md:text-lg">
                    {item.description}
                  </h1>
                </div>
              </motion.a>
            ))}
          </motion.div>
        )}
      </div>
    </>
  );
}

export default Admission;
