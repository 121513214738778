import React, { useEffect } from "react";
import { motion } from "framer-motion";
import SEO from "../components/SEO";

function Testimonial() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <SEO
        title="Testimonial"
        description="The Best Place To Enroll Your Child/Ward."
        name="CHARISMABASE"
        type="description"
      />
      <div className=" bg-[#FFF] py-10 lg:py-20 px-2 lg:px-24  flex flex-col  items-center  justify-center gap-x-1 ">
        <div className="flex items-center max-w-min gap-x-2 mx-auto mb-5 lg:mb-10">
          <div className="flex flex-col justify-end items-end space-y-1">
            <div className="w-10 h-0.5 rounded bg-[#a020f0] "></div>
            <div className="w-16 h-0.5 rounded bg-[#a020f0]"></div>
          </div>
          <h2 className="text-base text-[#a020f0] font-semibold  text-center md:text-xl uppercase mx-auto">
            Testimonial
          </h2>
          <div className="flex flex-col space-y-1">
            <div className="w-10 h-0.5 rounded bg-[#a020f0]"></div>
            <div className="w-16 h-0.5 rounded bg-[#a020f0]"></div>
          </div>
        </div>

        <motion.div
          initial={{ x: 20, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="grid gap-5 grid-cols-1 md:grid-cols-3 "
        >
          <motion.div
            initial={{ y: -70, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.9 }}
            className=" "
          >
            <div className="bg-[#F0FBFC] p-5 rounded-xl ">
              <p className="text-gray-900 text-sm md:text-base">
                Great Charismabase Standard School is a true definition of the
                name, they are always organized. This includes the Management
                and the Staffs
              </p>
              <div className="flex items-center gap-x-2 mt-3">
                <img
                  className="object-cover object-center w-7 h-7 md:w-9 md:h-9 rounded-full "
                  src="../Avatar.png"
                  alt=""
                />
                <div>
                  <h1 className="text-xs sm:text-sm font-semibold text-gray-800">
                    Izuchukwu Chidinma
                  </h1>
                  <p className="text-xs sm:text-sm text-gray-500 font-light">
                    Parent
                  </p>
                </div>
              </div>
            </div>
          </motion.div>
          <motion.div
            initial={{ y: -70, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.9 }}
            className=" "
          >
            <div className="bg-[#F0FBFC] p-5 rounded-xl ">
              <p className="text-gray-900 text-sm md:text-base">
                As a parent with two children enrolled, I have witnessed
                tremendous academic betterment on my kids. Their teachers are
                doing excellently well
              </p>
              <div className="flex items-center gap-x-2 mt-3">
                <img
                  className="object-cover object-center w-7 h-7 md:w-9 md:h-9 rounded-full "
                  src="../Avatar.png"
                  alt=""
                />
                <div>
                  <h1 className="text-xs sm:text-sm font-semibold text-gray-800">
                    Mrs. Esimbe Queendaline
                  </h1>
                  <p className="text-xs sm:text-sm text-gray-500 font-light">
                    Parent
                  </p>
                </div>
              </div>
            </div>
          </motion.div>
          <motion.div
            initial={{ y: -70, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.9 }}
            className=" "
          >
            <div className="bg-[#F0FBFC] p-5 rounded-xl ">
              <p className="text-gray-900 text-sm md:text-base">
                Great Charismabase Standard School is a pace setter, unique,
                other will always come after us. They are extremely good with
                what the do.
              </p>
              <div className="flex items-center gap-x-2 mt-3">
                <img
                  className="object-cover object-center w-7 h-7 md:w-9 md:h-9 rounded-full "
                  src="../Avatar.png"
                  alt=""
                />
                <div>
                  <h1 className="text-xs sm:text-sm font-semibold text-gray-800">
                    Pastor Samuel
                  </h1>
                  <p className="text-xs sm:text-sm text-gray-500 font-light">
                    Parent
                  </p>
                </div>
              </div>
            </div>
          </motion.div>
          <motion.div
            initial={{ y: -70, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.9 }}
            className=" "
          >
            <div className="bg-[#F0FBFC] p-5 rounded-xl ">
              <p className="text-gray-900 text-sm md:text-base">
                They genuinely care about their students' success and go the
                extra mile to help them achieve their goals. Charismabase is the
                best.
              </p>
              <div className="flex items-center gap-x-2 mt-3">
                <img
                  className="object-cover object-center w-7 h-7 md:w-9 md:h-9 rounded-full "
                  src="../Avatar.png"
                  alt=""
                />
                <div>
                  <h1 className="text-xs sm:text-sm font-semibold text-gray-800">
                    Charles Ben
                  </h1>
                  <p className="text-xs sm:text-sm text-gray-500 font-light">
                    Parent
                  </p>
                </div>
              </div>
            </div>
          </motion.div>
          <motion.div
            initial={{ y: -70, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.9 }}
            className=" "
          >
            <div className="bg-[#F0FBFC] p-5 rounded-xl ">
              <p className="text-gray-900 text-sm md:text-base">
                Charismabase one-on-one attention helped my son to develop a
                strong foundation in mathematics and other Science subjects
              </p>
              <div className="flex items-center gap-x-2 mt-3">
                <img
                  className="object-cover object-center w-7 h-7 md:w-9 md:h-9 rounded-full "
                  src="../Avatar.png"
                  alt=""
                />
                <div>
                  <h1 className="text-xs sm:text-sm font-semibold text-gray-800">
                    Michael Johnson
                  </h1>
                  <p className="text-xs sm:text-sm text-gray-500 font-light">
                    Parent
                  </p>
                </div>
              </div>
            </div>
          </motion.div>
          <motion.div
            initial={{ y: -70, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.9 }}
            className=" "
          >
            <div className="bg-[#F0FBFC] p-5 rounded-xl ">
              <p className="text-gray-900 text-sm md:text-base">
                Charismabase programs allowed my kids to gain real-world
                experience and apply my classroom knowledge in a professional
                setting.
              </p>
              <div className="flex items-center gap-x-2 mt-3">
                <img
                  className="object-cover object-center w-7 h-7 md:w-9 md:h-9 rounded-full "
                  src="../Avatar.png"
                  alt=""
                />
                <div>
                  <h1 className="text-xs sm:text-sm font-semibold text-gray-800">
                    Rachel Thompson
                  </h1>
                  <p className="text-xs sm:text-sm text-gray-500 font-light">
                    Parent
                  </p>
                </div>
              </div>
            </div>
          </motion.div>
        </motion.div>
      </div>
    </>
  );
}

export default Testimonial;
