import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Navigation } from "swiper/modules";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

function MainSlide() {
  const navigate = useNavigate();
  return (
    <>
      <Swiper
        slidesPerView={1}
        centeredSlides={true}
        spaceBetween={12}
        pagination={{
          clickable: true,
          renderBullet: function (index, className) {
            return (
              '<span className=" ' +
              className +
              '">' +
              "<div className=' bg-[#1b5958]  w-5 h-2 rounded-xl text-[#1b5958]'>  </div>" +
              "</span>"
            );
          },
        }}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Navigation]}
        breakpoints={{
          640: {
            slidesPerView: 1,
          },
        }}
        className="mySwiper "
        style={{
          "--swiper-navigation-color": "#d0d1d1",
          "--swiper-navigation-size": "30px",
          "--swiper-navigation-sides-offset": "30px",
          "--swiper-pagination-color": "#1b5958",
          "--swiper-pagination-bullet-size": "8px",
          "--swiper-pagination-bullet-horizontal-gap": "10px",
          "--swiper-pagination": "8px",
        }}
      >
        <SwiperSlide>
          <div className="relative flex items-end overflow-hidden">
            <img
              src="/GCSSimg/carousel1.jpg"
              alt="i"
              className="bg-cover  bg-center  w-full h-[22rem] lg:h-screen"
            />

            <div className="absolute   bg-[#181d38]/70  cursor-pointer pt-20 lg:pt-0 lg:h-screen w-full text-center">
              <div className="container mx-auto text-left text-white  px-10">
                <div className="flex items-center pt-[24rem] lg:py-[14rem]">
                  <div className="w-full lg:w-[57rem]">
                    <motion.p
                      initial={{ x: 300, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      exit={{ x: -300, opacity: 0 }}
                      className="text-sm lg:text-2xl font-bold text-[#a020f0] uppercase my-5 "
                    >
                      The Best Place To Enroll Your Child/Ward
                    </motion.p>
                    <h1 className="text-xl lg:text-7xl font-bold mb-4 ">
                      We Are Committed To Giving You The Best
                    </h1>
                    <motion.p
                      initial={{ y: 300, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: -300, opacity: 0 }}
                      className="text-sm lg:text-xl font-bold mb-5 "
                    >
                      Our vision is to empower students to acquire, demonstrate,
                      and articulate value, knowledge and skills...
                    </motion.p>

                    <div className="flex gap-x-3">
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/about");
                        }}
                        className="w-[10rem] bg-[#a020f0] text-sm lg:text-lg p-3 mb-9 text-white font-semibold transition duration-200 hover:bg-[#F4F7F7] hover:text-gray-700"
                      >
                        Read More
                      </button>

                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/search");
                        }}
                        className="w-[10rem] bg-[#fff] text-sm lg:text-lg p-3 mb-9 text-[#000] font-semibold transition duration-200 hover:bg-[#F4F7F7] hover:text-gray-700"
                      >
                        Read More
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="relative flex items-end overflow-hidden  lg:mt-0">
            <img
              src="/GCSSimg/carousel2.jpg"
              alt="i"
              className="bg-cover  bg-center  w-full h-[22rem] lg:h-screen"
            />

            <div className="absolute   bg-[#181d38]/70  cursor-pointer  lg:h-screen w-full text-center">
              <div className="container mx-auto text-left text-white  px-10">
                <div className="flex items-center pt-[24rem] lg:py-[14rem]">
                  <div className="w-full lg:w-[57rem]">
                    <motion.p
                      initial={{ x: 300, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      exit={{ x: -300, opacity: 0 }}
                      className="text-sm lg:text-2xl font-bold text-[#a020f0] uppercase my-5 "
                    >
                      Where First-class Education Meets Sound Moral Value
                    </motion.p>
                    <h1 className="text-xl lg:text-7xl font-bold mb-4 ">
                      Our Vision Is To Have Highly Productive Citizens In Our
                      Society..
                    </h1>
                    <motion.p
                      initial={{ y: 300, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: -300, opacity: 0 }}
                      className="text-sm lg:text-xl font-bold mb-5 "
                    >
                      We Nurture Our Students In Their Social, Moral, Spiritual
                      And Academic Development.
                    </motion.p>

                    <div className="flex gap-x-3">
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/about");
                        }}
                        className="w-[10rem] bg-[#a020f0] text-sm lg:text-lg p-3 mb-9 text-white font-semibold transition duration-200 hover:bg-[#F4F7F7] hover:text-gray-700"
                      >
                        Read More
                      </button>

                      <button
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                        className="w-[10rem] bg-[#fff] text-sm lg:text-lg p-3 mb-9 text-[#000] font-semibold transition duration-200 hover:bg-[#F4F7F7] hover:text-gray-700"
                      >
                        Enroll Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}

export default MainSlide;
