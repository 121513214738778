import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Navigation } from "swiper/modules";
import { useNavigate } from "react-router-dom";

function TestimonySlide() {
  const navigate = useNavigate();

  return (
    <Swiper
      slidesPerView={1.2}
      centeredSlides={true}
      spaceBetween={12}
      loop={true}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      modules={[Autoplay, Navigation]}
      breakpoints={{
        640: {
          slidesPerView: 2,
          spaceBetween: 12,
        },
        768: {
          slidesPerView: 3.2,
          spaceBetween: 12,
        },
        1024: {
          slidesPerView: 3.2,
          spaceBetween: 12,
        },
      }}
      className="mySwiper mt-5 "
    >
      <SwiperSlide>
        <div className="bg-[#F0FBFC] p-5 rounded-xl ">
          <p className="text-gray-900 text-sm md:text-base">
            Great Charismabase Standard School is a true definition of the name,
            they are always organized. This includes the Management and the
            Staffs
          </p>
          <div className="flex items-center gap-x-2 mt-3">
            <img
              className="object-cover object-center w-5 h-5 md:w-9 md:h-9 rounded-full "
              src="../Avatar.png"
              alt=""
            />
            <div>
              <h1 className="text-xs sm:text-sm font-semibold text-gray-800">
                Izuchukwu Chidinma
              </h1>
              <p className="text-xs sm:text-sm text-gray-500 font-light">
                Parent
              </p>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="bg-[#F0FBFC] p-5 rounded-xl ">
          <p className="text-gray-900 text-sm md:text-base">
            As a parent with two children enrolled, I have witnessed tremendous
            academic betterment upon my kids. Their teachers are doing
            excellently well
          </p>
          <div className="flex items-center gap-x-2 mt-3">
            <img
              className="object-cover object-center w-5 h-5 md:w-9 md:h-9 rounded-full "
          src="../Avatar.png"
              alt=""
            />
            <div>
              <h1 className="text-xs sm:text-sm font-semibold text-gray-800">
                Mrs. Esimbe Queendaline
              </h1>
              <p className="text-xs sm:text-sm text-gray-500 font-light">
                Parent
              </p>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="bg-[#F0FBFC] p-5 rounded-xl ">
          <p className="text-gray-900 text-sm md:text-base">
            Great Charismabase Standard School is a pace setter, unique, other
            will always come after us. They are extremely good with what the do.
          </p>
          <div className="flex items-center gap-x-2 mt-3">
            <img
              className="object-cover object-center w-5 h-5 md:w-9 md:h-9 rounded-full "
          src="../Avatar.png"
              alt=""
            />
            <div>
              <h1 className="text-xs sm:text-sm font-semibold text-gray-800">
                Pastor Samuel
              </h1>
              <p className="text-xs sm:text-sm text-gray-500 font-light">
                Parent
              </p>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="bg-[#F0FBFC] p-5 rounded-xl ">
          <p className="text-gray-900 text-sm md:text-base">
            They genuinely care about their students' success and go the extra
            mile to help them achieve their goals. Charismabase is the best.
          </p>
          <div className="flex items-center gap-x-2 mt-3">
            <img
              className="object-cover object-center w-5 h-5 md:w-9 md:h-9 rounded-full "
          src="../Avatar.png"
              alt=""
            />
            <div>
              <h1 className="text-xs sm:text-sm font-semibold text-gray-800">
                Charles Ben
              </h1>
              <p className="text-xs sm:text-sm text-gray-500 font-light">
                Parent
              </p>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="bg-[#F0FBFC] p-5 rounded-xl ">
          <p className="text-gray-900 text-sm md:text-base">
            Charismabase one-on-one attention helped my son to develop a strong
            foundation in mathematics and other Science subjects
          </p>
          <div className="flex items-center gap-x-2 mt-3">
            <img
              className="object-cover object-center w-5 h-5 md:w-9 md:h-9 rounded-full "
          src="../Avatar.png"
              alt=""
            />
            <div>
              <h1 className="text-xs sm:text-sm font-semibold text-gray-800">
                Michael Johnson
              </h1>
              <p className="text-xs sm:text-sm text-gray-500 font-light">
                Parent
              </p>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="bg-[#F0FBFC] p-5 rounded-xl ">
          <p className="text-gray-900 text-sm md:text-base">
            Charismabase programs allowed my kids to gain real-world experience
            and apply my classroom knowledge in a professional setting.
          </p>
          <div className="flex items-center gap-x-2 mt-3">
            <img
              className="object-cover object-center w-5 h-5 md:w-9 md:h-9 rounded-full "
          src="../Avatar.png"
              alt=""
            />
            <div>
              <h1 className="text-xs sm:text-sm font-semibold text-gray-800">
                Rachel Thompson
              </h1>
              <p className="text-xs sm:text-sm text-gray-500 font-light">
                Parent
              </p>
            </div>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
}

export default TestimonySlide;
