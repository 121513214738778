import { apiOne, apiTwo } from "./apiSlice";

export const allApiSlice = apiOne.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: `admin/login`,
        method: "POST",
        body: data,
      }),
    }),
    getAllCurriculum: builder.query({
      query: () => ({
        url: `content?category=curriculum&page=1&limit=10`,
        keepUnusedDataFor: 5,
      }),
    }),
    getAllAdmissionDoc: builder.query({
      query: () => ({
        url: `content?category=admission_document&page=1&limit=10`,
        keepUnusedDataFor: 5,
      }),
    }),
    getAllBlog: builder.query({
      query: () => ({
        url: `content?category=content&page=1&limit=10`,
        keepUnusedDataFor: 5,
      }),
    }),
    getAllGalleryImages: builder.query({
      query: () => ({
        url: `content?category=gallery&page=1&limit=100`,
        keepUnusedDataFor: 5,
      }),
    }),
    getAllStaffs: builder.query({
      query: () => ({
        url: `content?category=staff&page=1&limit=100`,
        keepUnusedDataFor: 5,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useGetAllAdmissionDocQuery,
  useGetAllCurriculumQuery,
  useGetAllStaffsQuery,
  useGetAllGalleryImagesQuery,
  useGetAllBlogQuery,
} = allApiSlice;

export const authApiSlice = apiTwo.injectEndpoints({
  endpoints: (builder) => ({
    // CURRICULUM
    createCurriculum: builder.mutation({
      query: (data) => ({
        url: `content/add`,
        method: "POST",
        body: data,
      }),
    }),

    updateCurriculum: builder.mutation({
      query: (data) => ({
        url: `content/${data.id}`,
        method: "PUT",
        body: data,
      }),
    }),
    deleteCurriculum: builder.mutation({
      query: (data) => ({
        url: `content/${data}`,
        method: "DELETE",
      }),
    }),

    // ADMISSION_DOCUMENT
    createAdmissionDoc: builder.mutation({
      query: (data) => ({
        url: `content/add`,
        method: "POST",
        body: data,
      }),
    }),

    updateAdmissionDoc: builder.mutation({
      query: (data) => ({
        url: `content/${data.id}`,
        method: "PUT",
        body: data,
      }),
    }),

    deleteAdmissionDoc: builder.mutation({
      query: (data) => ({
        url: `content/${data}`,
        method: "DELETE",
      }),
    }),

    // Announment
    createBlog: builder.mutation({
      query: (data) => ({
        url: `content/add`,
        method: "POST",
        body: data,
      }),
    }),

    updateBlog: builder.mutation({
      query: (data) => ({
        url: `content/${data.id}`,
        method: "PUT",
        body: data,
      }),
    }),
    deleteBlog: builder.mutation({
      query: (data) => ({
        url: `content/${data}`,
        method: "DELETE",
      }),
    }),

    // Gallery
    deleteImage: builder.mutation({
      query: (data) => ({
        url: `content/${data}`,
        method: "DELETE",
      }),
    }),

    createGallery: builder.mutation({
      query: (data) => ({
        url: `content/add`,
        method: "POST",
        body: data,
      }),
    }),  

    // Staffs
    
    createStaff: builder.mutation({
      query: (data) => ({
        url: `content/add`,
        method: "POST",
        body: data,
      }),
    }),
    deleteStaff: builder.mutation({
      query: (data) => ({
        url: `content/${data}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useCreateBlogMutation,
  useDeleteBlogMutation,
  useDeleteImageMutation,
  useCreateGalleryMutation,
  useCreateAdmissionDocMutation,
  useCreateCurriculumMutation,
  useCreateStaffMutation,
  useDeleteAdmissionDocMutation,
  useDeleteCurriculumMutation,
  useDeleteStaffMutation,
  useUpdateAdmissionDocMutation,
  useUpdateBlogMutation,
  useUpdateCurriculumMutation,
} = authApiSlice;
