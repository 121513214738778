import React, { useState } from "react";
import axios from "axios";

const CLOUDINARY_UPLOAD_PRESET = "pmnz4ina";
const CLOUDINARY_URL = "https://api.cloudinary.com/v1_1/dlptkcsc4/upload";

const MultiImageUploader = ({ setAllUploadedUrls }) => {
  const [images, setImages] = useState([]); // Store multiple images and their details
  
  // Handles multiple file selection
  const handleFilesChange = (e) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const newImages = Array.from(files).map((file) => ({
        file,
        preview: URL.createObjectURL(file), // Create local preview URL for the file
        secure_url: null, // This will hold the Cloudinary URL after upload
        isLoading: false, // Track loading state for each file
      }));

      setImages((prevImages) => [...prevImages, ...newImages]); // Add new files to the existing ones

      // Auto-upload all images after selection
      uploadAllImages([...newImages]);
    }
  };

  // Remove a selected image before upload
  const removeImage = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index)); // Remove the image at the given index
  };

  // Upload a single image to Cloudinary
  const uploadImage = async (image, index) => {
    // If the image is already uploaded (i.e., it has a URL), return the URL directly
    if (image.secure_url) {
      return image.secure_url;
    }

    const formData = new FormData();
    formData.append("file", image.file);
    formData.append("upload_preset", CLOUDINARY_UPLOAD_PRESET);

    try {
      // Update the specific image's loading status
      setImages((prevImages) =>
        prevImages.map((img, i) =>
          i === index ? { ...img, isLoading: true } : img
        )
      );

      const response = await axios.post(CLOUDINARY_URL, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      const imageUrl = response.data.secure_url;

      // Update the specific image's URL after successful upload
      setImages((prevImages) =>
        prevImages.map((img, i) =>
          i === index ? { ...img, url: imageUrl, isLoading: false } : img
        )
      );

      return imageUrl; // Return the uploaded URL
    } catch (error) {
      console.error("Error uploading to Cloudinary:", error);

      // Update the specific image's loading status to false on error
      setImages((prevImages) =>
        prevImages.map((img, i) =>
          i === index ? { ...img, isLoading: false } : img
        )
      );
      return null; // Return null if there was an error
    }
  };

  // Trigger the upload for all selected images and collect all URLs
  const uploadAllImages = async (newImages) => {
    const uploadPromises = newImages.map((image, index) => {
      // Ensure that if the image already has a URL, it gets returned directly, otherwise upload it
      return uploadImage(image, index);
    });

    // Wait for all upload promises to resolve
    const uploadedUrls = await Promise.all(uploadPromises);

    // Filter out any null values (in case some uploads failed)
    const validUrls = uploadedUrls.filter((url) => url !== null);

    // Update state with all uploaded URLs
    setAllUploadedUrls(validUrls);
    // setUploadImageUrl(validUrls);

    // Optionally: do something with the URLs, e.g., display them
    console.log("All uploaded URLs:", validUrls);
  };

  return (
    <div className="flex flex-col items-center justify-center ">
      {/* Input for selecting multiple files */}
      <div className="flex w-full ">
        <label
          className="cursor-pointer inline-flex items-center  -mr-24 px-3 py-2 bg-[#E4E4E4] text-gray-500 rounded-l-2xl font-semibold text-xs  hover:bg-gray-300 active:bg-[#E4E4E4] focus:outline-none disabled:opacity-25 transition ease-in-out duration-150 relative  w-[6rem] z-10"
          for="thumbnail"
        >
          Choose File
        </label>
        <input
          type="file"
          multiple
          onChange={handleFilesChange}
          accept=".png,.jpg,.pdf"
          className="py-3 px-8 border-[0.5px] bg-[#F6F8F8]/50 border-[#757E7E]/20 rounded-2xl w-full focus:outline-none text-xs"
        />
      </div>

      {/* Display previews and upload status */}
      <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
        {images.map((image, index) => (
          <div
            key={index}
            className="flex flex-col items-center justify-center  mx-auto py-2 text-center"
          >
            <img
              src={image.preview}
              alt={`Preview ${index + 1}`}
              width="150"
              height="150"
            />
            <div className="py-1">
              {image.isLoading ? (
                <p>Uploading...</p>
              ) : (
                <button onClick={() => removeImage(index)}>
                  <span className="text-red-500 cursor-pointer text-xl ">
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </span>
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MultiImageUploader;
