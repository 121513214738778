import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	userInfo: localStorage.getItem('userInfo')
		? JSON.parse(localStorage.getItem('userInfo'))
		: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      state.userInfo = action.payload;
      localStorage.setItem("userInfo", JSON.stringify(action.payload));
    },
    logout: (state, action) => {
      state.userInfo = null;
      // NOTE: here we need to also remove the cart from storage so the next
      // logged in user doesn't inherit the previous users cart and shipping
      localStorage.clear();
    },
    setTokens: (state, action) => {
      // Update tokens in the state
      state.userInfo.data.accessToken = action.payload.accessToken;
      state.userInfo.data.refreshToken = action.payload.refreshToken;
    },
  },
});

export const { setCredentials, logout, setTokens } = authSlice.actions;

export default authSlice.reducer;
