import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  return (
    <div className="z-[100] sticky top-0">
      <header className="bg-white  ">
        <motion.nav
          initial={{ x: -300, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="mx-auto hidden lg:flex  items-center justify-between   pl-20   "
          aria-label="Global"
        >
          <Link to="/" className="flex items-center gap-x-3 py-3">
            <img className="h-12  w-auto" src="../logo.png" alt="" />
            <h2 class="text-3xl text-[#a020f0] font-bold">G.C.S.S</h2>
          </Link>

          <div className="flex gap-10 items-center">
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive
                  ? "text-sm text-[#a020f0] transition-colors duration-300  p-1 hover:text-[#a020f0] font-bold uppercase"
                  : "text-sm text-[#000] transition-colors duration-300  hover:text-[#a020f0] uppercase  font-bold"
              }
            >
              Home
            </NavLink>
            <NavLink
              to="/about"
              className={({ isActive }) =>
                isActive
                  ? "text-sm text-[#a020f0] transition-colors duration-300  p-1 hover:text-[#a020f0] font-bold uppercase"
                  : "text-sm text-[#000] transition-colors duration-300  hover:text-[#a020f0] uppercase  font-bold"
              }
            >
              About Us
            </NavLink>
            <NavLink
              to="/gallery"
              className={({ isActive }) =>
                isActive
                  ? "text-sm text-[#a020f0] transition-colors duration-300  p-1 hover:text-[#a020f0] font-bold uppercase"
                  : "text-sm text-[#000] transition-colors duration-300  hover:text-[#a020f0] uppercase  font-bold"
              }
            >
              Gallery
            </NavLink>

            <div className="relative inline-block group">
              <button
                type="button"
                className="flex items-center gap-x-1 text-sm uppercase  font-bold  text-[#000] py-2.5"
              >
                Administration
                <svg
                  className="h-5 w-5 flex-none text-gray-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
              {/* <!-- Dropdown menu --> */}
              <div className="absolute -right-8 z-[100] w-44 p-2 hidden group-hover:block origin-top-right bg-white rounded-md shadow-xl ">
                <Link
                  to="/team"
                  className="w-full block px-4 py-2 text-sm text-gray-800  transition-colors duration-300 transform  hover:bg-gray-100  uppercase  font-bold rounded"
                >
                  Our Team
                </Link>
                <Link
                  to="/testimonial"
                  className="w-full block px-4 py-2 text-sm text-gray-800  transition-colors duration-300 transform  hover:bg-gray-100  uppercase  font-bold rounded"
                >
                  Testimonial
                </Link>
                <Link
                  to="/curriculum"
                  className="w-full block px-4 py-2 text-sm text-gray-800  transition-colors duration-300 transform  hover:bg-gray-100  uppercase  font-bold rounded"
                >
                  Curriculum
                </Link>
              </div>
            </div>
            <NavLink
              to="/contact"
              className={({ isActive }) =>
                isActive
                  ? "text-sm text-[#a020f0] transition-colors duration-300  p-1 hover:text-[#a020f0] font-bold uppercase"
                  : "text-sm text-[#000] transition-colors duration-300  hover:text-[#a020f0] uppercase  font-bold"
              }
            >
              Contact Us
            </NavLink>

            <button
              onClick={() => {
                navigate("/admission");
              }}
              className="w-[13rem] bg-[#a020f0] text-xl   py-6 text-center text-[#fff]  transition duration-200 hover:bg-[#757E7E] mx-auto hover:text-white"
            >
              Enroll Now<i class="fa fa-arrow-right ms-3"></i>
            </button>
          </div>
        </motion.nav>

        <div className="lg:hidden item-center  flex justify-between p-5 bg-[#fff] ">
          <Link to="/" className="flex items-center gap-x-3 ">
            <img className="h-10  w-auto" src="../logo.png" alt="" />
            <h2 class="text-3xl text-[#a020f0] font-bold">G.C.S.S</h2>
          </Link>

          <div className="flex lg:hidden">
            {isOpen ? (
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setIsOpen(false);
                }}
                className="text-[#000]  hover:text-gray-600  focus:outline-none focus:text-gray-600 "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="#576B74"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            ) : (
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setIsOpen(true);
                }}
                className="text-[#000]  hover:text-gray-600  focus:outline-none focus:text-gray-600 "
              >
                <svg
                  width="30"
                  height="26"
                  viewBox="0 0 30 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect y="10" width="30" height="6" rx="3" fill="#576B74" />
                  <rect y="20" width="15" height="6" rx="3" fill="#576B74" />
                  <rect width="20" height="6" rx="3" fill="#576B74" />
                </svg>
              </button>
            )}
          </div>
        </div>

        <div
          class={`${
            isOpen
              ? "translate-x-0 opacity-100 "
              : "opacity-0 -translate-x-full"
          } absolute inset-x-0 z-20 w-full px-6 py-4 transition-all duration-300 ease-in-out bg-[#fff] space-y-3`}
        >
          <div className="flex flex-col px-2 -mx-4 space-y-2">
            <Link
              to="/#"
              className="px-2.5 py-2 text-[#576B74] font-bold transition-colors duration-300 transform rounded-lg  hover:bg-gray-100  md:mx-2"
            >
              Home
            </Link>
            <Link
              to="/about"
              className="px-2.5 py-2 text-[#576B74] font-bold transition-colors duration-300 transform rounded-lg  hover:bg-gray-100  md:mx-2"
            >
              About Us
            </Link>
            <Link
              to="/gallery"
              className="px-2.5 py-2 text-[#576B74] font-bold transition-colors duration-300 transform rounded-lg  hover:bg-gray-100  md:mx-2"
            >
              Gallery
            </Link>
            <Link
              to="/team"
              className="px-2.5 py-2 text-[#576B74] font-bold transition-colors duration-300 transform rounded-lg  hover:bg-gray-100  md:mx-2"
            >
              Our Team
            </Link>
            <Link
              to="/testimonial"
              className="px-2.5 py-2 text-[#576B74] font-bold transition-colors duration-300 transform rounded-lg  hover:bg-gray-100  md:mx-2"
            >
              Testimonial
            </Link>
            <Link
              to="/curriculum"
              className="px-2.5 py-2 text-[#576B74] font-bold transition-colors duration-300 transform rounded-lg  hover:bg-gray-100  md:mx-2"
            >
              Curriculum
            </Link>
            <Link
              to="/team"
              className="px-2.5 py-2 text-[#576B74] font-bold transition-colors duration-300 transform rounded-lg  hover:bg-gray-100  md:mx-2"
            >
              Our Team
            </Link>{" "}
            <Link
              to="/testimonial"
              className="px-2.5 py-2 text-[#576B74] font-bold transition-colors duration-300 transform rounded-lg  hover:bg-gray-100  md:mx-2"
            >
              Testimonial
            </Link>
            <Link
              to="/contact"
              className="px-2.5 py-2 text-[#576B74] font-bold transition-colors duration-300 transform rounded-lg  hover:bg-gray-100  md:mx-2"
            >
              Contact Us
            </Link>
            <button
              onClick={() => {
                navigate("/admission");
              }}
              className="w-full md:w-[10rem] bg-[#a020f0] rounded-lg mt-2 py-4 px-2.5 text-[#fff]  transition duration-200 hover:bg-[#757E7E] mx-auto "
            >
              Enroll Now<i class="fa fa-arrow-right ms-3"></i>
            </button>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Header;
